var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Controller } from '@hotwired/stimulus';
import { init as init_JSModules } from "../js/import_modules.js";
import { sleep } from "../ts/utils";
export default class default_1 extends Controller {
    /**
     * async car la fonction utilise une fonction asynchrone: await fetch()
     */
    refreshContent({ params: { delay = 1 } }) {
        return __awaiter(this, void 0, void 0, function* () {
            // Récupération de la cible du reload :
            // - Si 'data-reload-content-target' existe, on le prend comme cible.
            // - Sinon, on récupère l'élément (div) où `stimulus_controller('reload-content')` est renseigné.
            let target = this.hasContentToReloadTarget ? this.contentToReloadTarget : this.element;
            // Attente du delay
            yield sleep(delay);
            // Envoi de l'event aux possibles autres controllers stimulus qui écoutent
            this.dispatch('refreshing', { target: target });
            // On change l'opacité pour un effet de reload
            target.style.opacity = "0.5";
            // Récupère l'URL en paramètre
            let url;
            if (this.urlValue) {
                // Récupère l'url passé en paramètre (relative) + la base de notre site
                url = new URL(this.urlValue, window.location.origin);
            }
            else {
                // Aucun paramètre, on récupère l'url actuelle
                url = new URL(window.location.href);
            }
            // Vérification si l'URL a le paramètre AJAX
            if (url.searchParams.has('ajax') === false) {
                url.searchParams.append('ajax', "1");
            }
            // Récupère la réponse de l'URL en AJAX
            const response = yield fetch(url.toString());
            const response_HTML = yield response.text();
            if (this.hasContentToReloadTarget) {
                // Recherche de 'contentToReload' dans l'HTML reçus
                const parser = new DOMParser();
                const response_dom = parser.parseFromString(response_HTML, 'text/html');
                const contentReloaded = response_dom.querySelector('[data-reload-content-target="contentToReload"]');
                // Remplace la target par le contenu
                target.replaceWith(contentReloaded);
                target = contentReloaded;
            }
            else {
                // Remplace TOUT l'HTML de la target par le contenu fetch
                target.innerHTML = response_HTML;
            }
            // ID obligatoire pour init_JSModules()
            if (target.id === "") {
                target.id = "id_" + Date.now();
            }
            // (Re)Init tous les modules JS (node_modules)
            init_JSModules(target, this.debugValue);
            // Retour à l'opacité de base
            target.style.opacity = "1";
            // Dispatch d'un évènement de FIN de reload
            this.dispatch('refreshed', { target: target });
        });
    }
}
// Cibles : `data-reload-content-target`
default_1.targets = [
    'contentToReload'
];
// Paramètres de `stimulus_controller('reload-content')`
default_1.values = {
    url: String,
    debug: Boolean,
};
