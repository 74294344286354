import { startStimulusApp } from '@symfony/stimulus-bridge';
import {definitionsFromContext} from "@hotwired/stimulus-webpack-helpers"

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
    true,
    /\.[jt]sx?$/
));

/* AllsoftwareBundle */
const allsoftwareBundleContext = require.context(
    "../lib/allsoftware-symfony-bundle/assets/controllers",
    true,
    /\.(j|t)sx?$/
)
app.load(definitionsFromContext(allsoftwareBundleContext))
