import './modules/dark-theme.ts'

import * as _ from 'lodash';

import 'bootstrap-sass/assets/javascripts/bootstrap/transition.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/alert.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown.js';
import 'bootstrap-sass/assets/javascripts/bootstrap/modal.js';
import 'jquery';

import 'animate.css';

import Cookies from 'js-cookie'

import autosize from 'autosize';
import * as bootstrap from 'bootstrap';
import {Dropdown, Popover, Tab, Toast, Tooltip} from 'bootstrap';
import Inputmask from "inputmask";

import TomSelect from "tom-select/dist/js/tom-select.complete";
import "tom-select/dist/css/tom-select.bootstrap5.min.css";

import 'quill/dist/quill.snow.css';
import Quill from 'quill';
import 'quill-mention/dist/quill.mention.min.css';
import 'quill-mention';

import Sortable, {MultiDrag} from "sortablejs";

Sortable.mount(new MultiDrag());

import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

import 'fslightbox'

import TablerButtonLoader from "./modules/TablerButtonLoader";
import TablerModal from "./modules/TablerModal";
import {TablerToast, TablerToastContainer} from "./modules/TablerToast";

const redirectTo = Cookies.get("redirectTo")
if (redirectTo) {
    Cookies.remove("redirectTo")
    window.location.href = redirectTo
}

window.bootstrap = bootstrap;
window.initialized = [];
let areYouSureInitialized = false

export let swup = null;
// Conteneur des toasts
export const toastContainer = new TablerToastContainer();

// ===== Avant mount de SWUP =====
document.body.addEventListener('swup:pre-connect', function (event) {
    // Récupération des options de SWUP depuis Symfony/UX
    const options = event.detail.options;

    // Ajout plugin "Scripts", eg: https://swup.js.org/plugins/scripts-plugin
    // options.plugins.push(new SwupScriptsPlugin())
});

// ===== Récupération de SWUP =====
document.body.addEventListener('swup:connect', function (event) {
    swup = event.detail.swup;

    swup.on('contentReplaced', () => {
        swup.options.containers.forEach((selector) => {
            // Init module pour tout les elements que SWUP à "rechargé" => 'selector' (ex: #swup)
            init(document.querySelector(selector));
        });
    });
});

// Branchement de l'Event 'dom:module:init' pour initialiser les modules dans le DOM demandé
document.addEventListener('dom:module:init', function (e) {
    // Récupération du dom depuis les paramètres de CustomEvent
    const dom = e.detail.dom;
    // Initialisation des modules
    init(dom);
});

document.addEventListener("DOMContentLoaded", () => {
    const loader = document.getElementById('loader')
    if (!!loader && loader.classList.contains('d-none') === false) {
        loader.addEventListener('animationend', () => loader.remove(), {once: true});
        loader.classList.add('animate__animated', 'animate__fadeOut')
    }
});

/**
 * Init de tous les modules
 *
 * @param {HTMLElement} dom
 * @param {boolean} debug
 */
export function init(dom, debug = false) {

    if (debug) console.info('Init JS modules for ' + (dom.id || dom.classList || 'unknown'), dom);

    /** Bootstrap **/
    init_bootstrapTab(dom);
    init_bootstrapDropdown(dom);
    init_bootstrapPopovers(dom);
    init_bootstrapToast(dom);
    init_bootstrapTooltip(dom);

    /** Modules yarn **/
    init_tomSelect(dom);
    init_quill(dom);
    init_autosize(dom);
    init_inputMask(dom);
    init_sortable(dom);
    init_plyr(dom);
    refreshFsLightbox()

    /** Custom **/
    init_buttonFormLoader(dom);
    init_modalArchive(dom);
    init_tableTrHref(dom);
    checkForDownload();
    areYouSure(dom);
}


/**
 * Initialisation Bootstrap Tab
 * @param {HTMLElement} dom
 */
export function init_bootstrapTab(dom) {

    const locationHash = window.location.hash;

    // L'url en cours à un hash "https//....#hash"
    if (locationHash) {
        // Pour tous les "tab" bootstrap
        const tabsList = [].slice.call(dom.querySelectorAll('[data-bs-toggle="tab"]'));
        // Recherche de l'onglet avec le nom du hash en cours
        // e.g: data-bs-target="#hash"
        const matchedTabs = tabsList.filter(tab => tab.dataset.bsTarget === locationHash);

        matchedTabs.map(tab => {
            // Affichage de l'onglet avec le même hash
            new Tab(tab).show();
        });
    }

    dom
        // Pour tous les "tab" bootstrap
        .querySelectorAll('[data-bs-toggle="tab"]')
        .forEach((tab) => {
                // À l'affichage du tab
                tab.addEventListener('shown.bs.tab', (event) => {
                    const hash = event.target.dataset.bsTarget
                    // Modification du hash de l'url en cours
                    history.pushState({}, "", hash)
                })
            }
        )
}

/**
 * Initialisation Boostrap DropDown
 * @param {HTMLElement} dom
 */
export function init_bootstrapDropdown(dom) {
    dom.querySelectorAll('[data-bs-toggle="dropdown"]').forEach(function (dropdownTriggerEl) {
        return new Dropdown(dropdownTriggerEl);
    });
}

/**
 * Initialisation Boostrap Popovers
 * @param {HTMLElement} dom
 */
export function init_bootstrapPopovers(dom) {
    dom.querySelectorAll('[data-bs-toggle="popover"]').forEach(function (popoverTriggerEl) {
        let options = {
            delay: {show: 50, hide: 50},
            html: popoverTriggerEl.getAttribute('data-bs-html') === "true" ?? false,
            placement: popoverTriggerEl.getAttribute('data-bs-placement') ?? 'auto'
        };
        return new Popover(popoverTriggerEl, options);
    });
}

/**
 * Initialisation Boostrap Toast
 * @param {HTMLElement} dom
 */
export function init_bootstrapToast(dom) {
    dom.querySelectorAll('[data-bs-toggle="toast"]').forEach(function (toastTriggerEl) {
        return new Toast(toastTriggerEl);
    });
}

/**
 * Initialisation Boostrap Tooltip
 * @param {HTMLElement} dom
 */
export function init_bootstrapTooltip(dom) {
    dom.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(function (tooltipTriggerEl) {
        let options = {
            delay: {show: 50, hide: 50},
            html: tooltipTriggerEl.getAttribute("data-bs-html") === "true" ?? false,
            placement: tooltipTriggerEl.getAttribute('data-bs-placement') ?? 'auto',
            container: '#' + dom.id,
        };
        return new Tooltip(tooltipTriggerEl, options);
    });
}

/**
 * Initialisation Plugin AutoSize
 * @param {HTMLElement} dom
 */
export function init_autosize(dom) {
    dom.querySelectorAll('[data-bs-toggle="autosize"]')
        .forEach(function (element) {
            /** @type HTMLTextAreaElement  */
            const textarea = element

            autosize(textarea)
            setTimeout(() => {
                if (textarea.value.length > 0) {
                    autosize.update(textarea)
                }
            }, 100)
        })
}

/**
 * Initialisation Plugin InputMask
 * @param {HTMLElement} dom
 */
export function init_inputMask(dom) {
    dom.querySelectorAll('input[data-input-mask]')
        .forEach(input => {
            Inputmask({
                'mask': input.getAttribute("data-input-mask"),
                'greedy': _isGreedy(input),
            }).mask(input);
        })
    ;

    dom.querySelectorAll('input[data-inputmask-alias], input[data-inputmask-regex]')
        .forEach(input => {
            Inputmask().mask(input);
        })
    ;

    /**
     *
     * @param {HTMLInputElement} input
     * @return {boolean}
     * @private
     */
    function _isGreedy(input) {
        return _attributeIsTrue(input, 'data-input-mask-greedy')
    }
}

/**
 * Initialisation Plugin Tom Select (JS) : https://github.com/orchidjs/tom-select
 * @param {HTMLElement} dom
 */
export function init_tomSelect(dom) {

    const default_options = {
        create: false,
        allowEmptyOption: false,
        placeholder: undefined,
        plugins: {
            dropdown_input: true,
            drag_drop: false,
            caret_position: true,
            remove_button: {
                title: 'Supprimer cet élément',
            },
            clear_button: {
                title: 'Supprimer tous les éléments sélectionnés',
            },
        },
        render: {
            option_create: function (data, escape) {
                return '<div class="create">Créer nouvelle entrée : <strong>' + escape(data.input) + '</strong>&hellip;</div>';
            },
            no_results: function (data, escape) {
                return '<div class="no-results">Aucun résultat trouvé</div>';
            },
        }
    }

    deepFreeze(default_options);

    const dom_selector = dom.id ? "#" + dom.id : "." + dom.classList;

    dom.querySelectorAll(".tom-select")
        .forEach(function (element) {
            let select_options = _getOptionsFromAttrData(element);
            new TomSelect(dom_selector + " #" + element.id, select_options);
        })
    ;

    const CST_TomSelectAjax_Attr_ValueField = 'data-tom-value-field';
    const CST_TomSelectAjax_Attr_LabelField = 'data-tom-label-field';
    const CST_TomSelectAjax_Attr_SearchField = 'data-tom-search-field';
    const CST_TomSelectAjax_Attr_Url = 'data-tom-url';
    const CST_TomSelectAjax_Attr_Options = 'data-tom-options';

    dom.querySelectorAll(".tom-select-AJAX")
        .forEach(function (element) {

            let select_options = _getOptionsFromAttrData(element);
            select_options.options = []

            if (element.hasAttribute(CST_TomSelectAjax_Attr_ValueField)) {
                select_options.valueField = element.getAttribute(CST_TomSelectAjax_Attr_ValueField)
            } else throw new Error(`[tom-select-AJAX] Input require data attr "${CST_TomSelectAjax_Attr_ValueField}"`)

            if (element.hasAttribute(CST_TomSelectAjax_Attr_LabelField)) {
                select_options.labelField = element.getAttribute(CST_TomSelectAjax_Attr_LabelField)
            } else throw new Error(`[tom-select-AJAX] Input require data attr "${CST_TomSelectAjax_Attr_LabelField}"`)

            if (element.hasAttribute(CST_TomSelectAjax_Attr_SearchField)) {
                const JSON_searchField = element.getAttribute(CST_TomSelectAjax_Attr_SearchField)
                select_options.searchField = JSON.parse(JSON_searchField)
            } else throw new Error(`[tom-select-AJAX] Input require data attr "${CST_TomSelectAjax_Attr_SearchField}"`)

            if (element.hasAttribute(CST_TomSelectAjax_Attr_Url) === false) {
                throw new Error(`[tom-select-AJAX] Input require data attr "${CST_TomSelectAjax_Attr_Url}"`)
            }

            if (element.hasAttribute(CST_TomSelectAjax_Attr_Options)) {
                const JSON_options = element.getAttribute(CST_TomSelectAjax_Attr_Options)
                select_options.options = JSON.parse(!!JSON_options ? JSON_options : "[]")
            }

            if (element.hasAttribute('multiple') === false) {
                select_options.maxItems = 1
            }

            const urlAttr = element.getAttribute(CST_TomSelectAjax_Attr_Url)
            select_options.load = function (query, callback) {
                const urlSearch = new URL(urlAttr)
                urlSearch.searchParams.set('q', encodeURIComponent(query))

                fetch(urlSearch)
                    .then(response => response.json())
                    .then(json => callback(json))
                    .catch(e => callback())
                ;
            };

            if (element.value) {
                const urlSelectedData = new URL(urlAttr)
                urlSelectedData.searchParams.set('value', encodeURIComponent(element.value))

                fetch(urlSelectedData)
                    .then(response => response.json())
                    .then(json => {
                        const options = [],
                            items = []

                        json.forEach((data) => {
                            const value = String(data[select_options.valueField])
                            const label = String(data[select_options.labelField])

                            const datum = {}
                            datum[select_options.valueField] = value
                            datum[select_options.labelField] = label
                            options.push(datum)

                            items.push(value)
                        })

                        select_options.options = select_options.options.concat(options)
                        select_options.items = items

                        new TomSelect(dom_selector + " #" + element.id, select_options);
                    })
                ;
            } else {
                new TomSelect(dom_selector + " #" + element.id, select_options);
            }
        })
    ;

    dom.querySelectorAll(".tom-tag")
        .forEach(function (element) {
            new TomSelect(dom_selector + " #" + element.id, _.assign({}, {
                ...default_options,
                ...{
                    createOnBlur: true,
                    create: true,
                }
            }));
        })
    ;

    function deepFreeze(obj) {
        Object.keys(obj).forEach(prop => {
            if (typeof obj[prop] === 'object' && !Object.isFrozen(obj[prop])) deepFreeze(obj[prop]);
        });
        return Object.freeze(obj);
    }

    /**
     * @param {Element} element
     * @private
     */
    function _getOptionsFromAttrData(element) {

        let select_options = _.merge(
            {},
            {...default_options},
            {
                create: element.hasAttribute('data-tom-create') ? _attributeIsTrue(element, 'data-tom-create') : default_options.create,
                allowEmptyOption: element.hasAttribute('data-tom-allow-empty-option') ? _attributeIsTrue(element, 'data-tom-allow-empty-option') : default_options.allowEmptyOption,
                placeholder: element.hasAttribute('data-tom-placeholder') ? element.getAttribute('data-tom-placeholder') : default_options.placeholder,
                shouldLoad: function (query) {
                    return query.length >= parseInt(element.getAttribute('data-tom-query-length'))
                }
            }
        );

        if (_attributeIsTrue(element, 'data-tom-dropdown-input') === false) delete select_options.plugins.dropdown_input;
        if (_attributeIsTrue(element, 'data-tom-remove-button') === false) delete select_options.plugins.remove_button;
        if (_attributeIsTrue(element, 'data-tom-clear-button') === false) delete select_options.plugins.clear_button;
        if (_attributeIsTrue(element, 'data-tom-drag-drop') === false) delete select_options.plugins.drag_drop;
        if (_attributeIsTrue(element, 'data-tom-caret-position') === false) delete select_options.plugins.caret_position;

        return select_options;
    }
}

/**
 * Initialisation Plugin Quill (WYSIWYG) : https://github.com/quilljs/quill
 * @param {HTMLElement} dom
 */
export function init_quill(dom) {

    const CST_Quill_Mention_Attr_Url = 'data-quill-mention-url';
    const CST_Quill_Mention_Attr_AllowedChars = 'data-quill-mention-allowed-chars';
    const CST_Quill_Mention_Attr_DenotationChars = 'data-quill-mention-denotation-chars';
    const CST_Quill_Mention_Attr_ShowDenotationChar = 'data-quill-mention-show-denotation-char';

    const default_options = {
        readOnly: false,
        theme: 'snow',
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                // ['blockquote', 'code-block'],

                [{'list': 'ordered'}, {'list': 'bullet'}],
                // [{ 'script': 'sub'}, { 'script': 'super' }],   // superscript/subscript
                [{'align': []}],
                [{'indent': '-1'}, {'indent': '+1'}],             // outdent/indent

                // [{ 'font': [] }],
                [{'header': 1}, {'header': 2}],                   // custom button values
                [{'size': ['small', false, 'large', 'huge']}],    // custom dropdown
                [{'header': [1, 2, 3, 4, false]}],
                [{'color': []}, {'background': []}],              // dropdown with defaults from theme

                // ['clean']                                      // remove formatting button
            ],
        }
    };

    dom.querySelectorAll(".quill-symfony")
        .forEach(input_source => {
            let options = {...default_options};

            options.readOnly = input_source.readOnly;

            const withToolbar = _attributeIsTrue(input_source, 'data-quill-show-toolbar') === false;
            if (withToolbar) options.modules.toolbar = [];

            if (input_source.hasAttribute(CST_Quill_Mention_Attr_Url)) {
                const mention = {
                    // allowedChars: /^([a-zA-Z0-9_]|[^\w\s])*$/,
                    allowedChars: new RegExp(input_source.getAttribute(CST_Quill_Mention_Attr_AllowedChars)),
                    showDenotationChar: _attributeIsTrue(input_source, CST_Quill_Mention_Attr_ShowDenotationChar),
                    source: async function (searchTerm, renderList, mentionChar) {

                        const url = new URL(input_source.getAttribute(CST_Quill_Mention_Attr_Url));

                        url.searchParams.set('mentionChar', mentionChar);
                        url.searchParams.set('searchTerm', searchTerm);

                        await fetch(url.toString())
                            .then(response => response.json())
                            .then(json => {
                                const mentions = json.filter(mention => mention.value.includes(searchTerm) && mention.denotationChar === mentionChar);

                                renderList(mentions);
                            })
                        ;
                    }
                }

                const JSON_DenotationChars = input_source.getAttribute(CST_Quill_Mention_Attr_DenotationChars)
                mention.mentionDenotationChars = JSON.parse(JSON_DenotationChars);

                options.modules = {
                    ...options.modules,
                    ...{mention: mention}
                }
            }

            _createInputHTML(input_source, options);
        })
    ;

    function _createInputHTML(input, options) {
        input.hidden = true;

        const quillEditor = document.createElement("div");
        quillEditor.id = input.id + "_quillSymfony";
        quillEditor.class = 'quill';
        quillEditor.innerHTML = input.value;

        const container = document.createElement("div"); // container to be safe
        container.appendChild(quillEditor);
        input.after(container);

        const quill = new Quill(quillEditor, options);

        if (options.modules.toolbar.length === 0) container.querySelector('.ql-toolbar').remove();

        if (window.initialized.quill === undefined) window.initialized.quill = [];
        window.initialized.quill[input.id] = quill;

        quill.on('text-change', function () {
            input.value = quill.root.innerHTML;
        });

        return quill;
    }
}

/**
 * Initialisation Plugin SortableJS : https://github.com/SortableJS/Sortable
 * @param {HTMLElement} dom
 */
export function init_sortable(dom) {

    const default_options = {
        draggable: '.sortable-js-item',
        handle: false,
        animation: 150,
        ghostClass: 'bg-grey',
        multiDrag: true, // Enable multi-drag
        selectedClass: 'active', // The class applied to the selected items
        multiDragKey: 'CTRL', // Key that must be down for items to be selected
        fallbackTolerance: 3, // So that we can select items on mobile
    };

    const events = [
        'onChoose',
        'onStart',
        'onEnd',
        'onAdd',
        'onUpdate',
        'onSort',
        'onRemove',
        'onChange',
        'onUnchoose'
    ];

    dom.querySelectorAll('.sortable-js')
        .forEach(sortableEl => {
            let options = _.assign({}, default_options);

            options.draggable = sortableEl.dataset.sortableDraggable || default_options.draggable;
            options.handle = sortableEl.dataset.sortableHandle || default_options.handle;
            options.ghostClass = sortableEl.dataset.sortableGhostClass || default_options.ghostClass;
            options.selectedClass = sortableEl.dataset.sortableSelectedClass || default_options.selectedClass;
            options.multiDragKey = sortableEl.dataset.sortableMultiDragKey || default_options.multiDragKey;

            events.forEach(function (name) {
                options[name] = (sortableEvent) => {
                    const sortable_event = new CustomEvent('sortable:' + name, {detail: {sortableEvent}});
                    sortableEl.dispatchEvent(sortable_event);
                };
            });

            Sortable.create(sortableEl, options);
        });
}


/**
 * @param {HTMLElement} dom
 */
export function init_plyr(dom) {
    new Plyr(document.querySelectorAll('.plyr'));
}


/**
 * @param {HTMLElement} dom
 */
export function init_buttonFormLoader(dom) {
    dom.querySelectorAll("form").forEach(form => {
        new TablerButtonLoader(form)
    })
}

/**
 * @param {HTMLElement} dom
 */
export function init_modalArchive(dom) {
    dom.querySelectorAll("*[data-action='modal-archive']")
        .forEach(el => {
            const errors = new Set()
            const url = el.dataset.url
            if (!url) {
                errors.add('Data "url" is required!')
            }

            const csrf = el.dataset.csrf
            if (!csrf) {
                errors.add('Data "csrf" is required!')
            }

            if (errors.size > 0) {
                console.error('Modal archive:', errors, el)
                el.classList.add('disabled')
                el.setAttribute('disabled', '')
                return
            }

            const redirectTo = el.dataset.redirectTo ?? undefined

            el.addEventListener('click', (e) => onArchive(url, csrf, redirectTo))
        })

    async function onArchive(url, csrf, redirectTo = undefined) {
        const modal = new TablerModal()
        const confirm = await modal.confirm()
        if (confirm === false) {
            return
        }

        sendDeleteRequest(url, csrf)
            .then(async (response) => {
                if (response.ok === false) {
                    console.error(response)
                    modal.close()
                    return Promise.reject()
                }

                if (redirectTo) {
                    // Has redirectTo data param
                    window.location.replace(redirectTo)
                } else if (response.redirected) {
                    // Response wants to redirect
                    window.location = response.url
                } else {
                    // Refresh the current page
                    window.location = window.location.href.split('#')[0]
                }
            })
            .catch((e) => {
                TablerModal.error()
            })
    }

    /**
     * @return {Promise<Response>}
     */
    function sendDeleteRequest(url, csrf) {

        const body = new FormData()
        body.append('_token', csrf)

        return fetch(url, {
            method: 'POST',
            body: body,
        })
    }
}

/**
 * @param {HTMLElement} dom
 */
export function init_tableTrHref(dom) {
    dom.querySelectorAll('tr[data-href]').forEach((tr) => {
        tr.addEventListener('click', function (e) {
            if (window.getSelection().type === "Range") {
                return;
            }

            /** @type HTMLElement */
            const target = e.target
            if (target instanceof HTMLAnchorElement || target instanceof HTMLInputElement) {
                return;
            }

            /** @type HTMLTableCellElement|null */
            const td = target.closest('td');
            if (!td) {
                return;
            } else if ('noHref' in td.dataset) {
                return;
            }

            if ('hrefSimulateButton' in tr.dataset) {
                // Simulation du click sur bouton ouverture de modale
                const button = tr.querySelector('[data-action="modal-form#openModal"]');
                button.click()
            } else {
                const dataTarget = tr.dataset.target;
                if (dataTarget) {
                    // Attribut target présent, ouverture onglet
                    window.open(tr.dataset.href, dataTarget);
                } else {
                    // Redirection onglet actuel
                    window.location = tr.dataset.href;
                }
            }
        });
    });
}

export function checkForDownload() {
    setInterval(() => {
        if (Cookies.get("fileDownload")) {
            Cookies.remove("fileDownload");

            const toast = new TablerToast('Fichier(s) présent(s) dans vos "Téléchargements"', 'Fichier(s) téléchargé(s) !');
            toast.delay = 10_000
            // toast.bgColor = 'success-lt'
            toastContainer.pushToast(toast);
        }
    }, 1000);
}

export function areYouSure(dom) {
    dom.querySelectorAll('form[confirm-on-leave] input, form[confirm-on-leave] textarea, form[confirm-on-leave] select').forEach((input) => {
        input.addEventListener("change", function (e) {
            input.form.setAttribute("data-changed", true);
        })

        input.addEventListener("select", function (e) {
            e.target.form.setAttribute("data-changed", true);
        })

        if (!!window.initialized.quill && window.initialized.quill[input.id]){
            window.initialized.quill[input.id].on('text-change',function () {
                input.form.setAttribute("data-changed", true);
            })
        }
    });

    dom.querySelectorAll('form[confirm-on-leave]').forEach((form) => {
        form.addEventListener('submit', function (e) {
            form.removeAttribute("data-changed");
        })
        form.addEventListener('modal:form:submit', function (e) {
            form.removeAttribute("data-changed");
        })
    })

    if (areYouSureInitialized === false) {
        window
            .addEventListener("beforeunload", function (event) {
                if (dataWillBeLost()) {
                    const confirmationMessage = "\\o/";

                    // Gecko + IE
                    (event || window.event).returnValue = confirmationMessage;

                    // Safari, Chrome, and other WebKit-derived browsers
                    return confirmationMessage;
                }
            })

        document.querySelectorAll('.modal').forEach((modal) => {
            modal.addEventListener('hide.bs.modal', function (event) {
                if (dataWillBeLost()) {
                    if (confirm("Cette page vous demande de confirmer sa fermeture ; des données que vous avez saisies pourraient ne pas être enregistrées.") === false) {
                        event.preventDefault();
                    }
                }
            })
        })
        areYouSureInitialized = true
    }

    function dataWillBeLost() {
        return document.querySelectorAll('form[confirm-on-leave][data-changed]').length > 0
    }

}

/**
 * Fonction permettant de savoir si un attribut HTML est à `true`
 *
 * @param {HTMLInputElement} input
 * @param {string} attribute_name
 * @return {boolean}
 * @private
 */
function _attributeIsTrue(input, attribute_name) {
    return input.hasAttribute(attribute_name) ? ["1", true, attribute_name].includes(input.getAttribute(attribute_name)) : false;
}
