import {Controller} from '@hotwired/stimulus';
import {
    AmpPlugin,
    easepick,
    KbdPlugin,
    LockPlugin,
    PresetPlugin,
    TimePlugin,
    RangePlugin,
    DateTime
} from '@easepick/bundle';
import css_picker from '!!raw-loader!@easepick/bundle/dist/index.css';
import css_picker_tabler from '!!raw-loader!../styles/modules/easepick.css';

export default class EasepickInput extends Controller {
    static values = {
        firstDay: Number,
        lang: String,
        date: String,
        format: String,
        grid: Number,
        calendars: Number,
        readonly: Boolean,
        autoApply: Boolean,
        inline: Boolean,
        header: String,
        locale: Object,
        // ** Plugin : Range
        ranges: {type: Boolean, default: false},
        elementEnd: String,
        startDate: String,
        endDate: String,
        repick: Boolean,
        strict: Boolean,
        delimiter: String,
        tooltip: Boolean,
        rangeLocale: Object,
        // ** Plugin : AMP
        dropdown: Object,
        resetButton: Boolean,
        darkMode: Boolean,
        weekNumbers: Boolean,
        ampLocale: Object,
        // ** Plugin : KDB
        keyboards: {type: Boolean, default: false},
        unitIndex: Number,
        dayIndex: Number,
        // ** Plugin : Lock
        minDate: String,
        maxDate: String,
        minDays: String,
        maxDays: String,
        selectForward: Boolean,
        selectBackward: Boolean,
        presets: Boolean,
        inseparable: Boolean,
        // filter : Custom
        allowedDays: String,
        disallowedDays: String,
        // ** Plugin : Preset
        customPreset: Array,
        customLabels: Array,
        position: String,
        // ** Plugin : Time
        times: {type: Boolean, default: false},
        seconds: Boolean,
        stepHours: Number,
        stepMinutes: Number,
        stepSeconds: Number,
        format12: Boolean,
    }

    get firstDay() {
        return this.firstDayValue
    }

    get lang() {
        return this.langValue
    }

    get date() {
        return this.dateValue
    }

    get format() {
        return this.formatValue
    }

    get grid() {
        return this.gridValue
    }

    get calendars() {
        return this.calendarsValue
    }

    get readonly() {
        return this.readonlyValue
    }

    get autoApply() {
        return this.autoApplyValue
    }

    get inline() {
        return this.inlineValue
    }

    get header() {
        return this.headerValue ?? false
    }

    get locale() {
        return this.localeValue
    }

    // ** Plugin : Range

    get ranges() {
        return this.rangesValue
    }

    get elementEnd() {
        return this.elementEndValue ? this.elementEndValue : null
    }

    get startDate() {
        return this.startDateValue ? this.startDateValue : null
    }

    get endDate() {
        return this.endDateValue ? this.endDateValue : null
    }

    get repick() {
        return this.repickValue
    }

    get strict() {
        return this.strictValue
    }

    get delimiter() {
        return this.delimiterValue
    }

    get tooltip() {
        return this.tooltipValue
    }

    get rangeLocale() {
        return this.rangeLocaleValue
    }

    // ** Plugin : AMP

    get dropdown() {
        return this.dropdownValue
    }

    get resetButton() {
        return this.resetButtonValue
    }

    get darkMode() {
        return this.darkModeValue
    }

    get weekNumbers() {
        return this.weekNumbersValue
    }

    get ampLocale() {
        return this.rangeLocaleValue
    }

    // ** Plugin : KDB

    get keyboards() {
        return this.keyboardsValue
    }

    get unitIndex() {
        return this.unitIndexValue
    }

    get dayIndex() {
        return this.dayIndexValue
    }

    // ** Plugin : Lock

    get minDate() {
        return this.minDateValue ? this.minDateValue : null
    }

    get maxDate() {
        return this.maxDateValue ? this.maxDateValue : null
    }

    get minDays() {
        return this.minDaysValue ? parseInt(this.minDaysValue) : null
    }

    get maxDays() {
        return this.maxDaysValue ? parseInt(this.maxDaysValue) : null
    }

    get selectForward() {
        return this.selectForwardValue
    }

    get selectBackward() {
        return this.selectBackwardValue
    }

    get presets() {
        return this.presetsValue
    }

    get inseparable() {
        return this.inseparableValue
    }

    // filter : Custom

    get allowedDays() {
        return this.allowedDaysValue ? JSON.parse(this.allowedDaysValue) : null
    }

    get disallowedDays() {
        return this.disallowedDaysValue ? JSON.parse(this.disallowedDaysValue) : null
    }

    // ** Plugin : Preset

    get customPreset() {
        return this.customPresetValue
    }

    get customLabels() {
        return this.customLabelsValue
    }

    get position() {
        return this.positionValue
    }

    // ** Plugin : Time

    get times() {
        return this.timesValue
    }

    get seconds() {
        return this.secondsValue
    }

    get stepHours() {
        return this.stepHoursValue
    }

    get stepMinutes() {
        return this.stepMinutesValue
    }

    get stepSeconds() {
        return this.stepSecondsValue
    }

    get format12() {
        return this.format12Value
    }

    connect() {
        super.connect()

        const picker = new easepick.create(this._getOptions());

        picker.on('render', (event) => {
            this.dispatch('render', {detail: {event: event, picker: picker}, prefix: false})
        })

        picker.on('view', (event) => {
            this.dispatch('view', {detail: {event: event, picker: picker}, prefix: false})
        })

        picker.on('preselect', (event) => {
            this.dispatch('preselect', {detail: {event: event, picker: picker}, prefix: false})
        })

        picker.on('select', (event) => {
            this.dispatch('select', {detail: {event: event, picker: picker}, prefix: false})
        })

        picker.on('clear', (event) => {
            this.dispatch('clear', {detail: {event: event, picker: picker}, prefix: false})
        })

        this.element.addEventListener('keyup', (event) => {

            const value = event.target.value

            const matches = [];
            let m = null;

            while ((m = DateTime.regex.exec(this.format)) != null) {
                if (m[1] === '\\') continue; // delete when regexp lookbehind
                matches.push(m);
            }

            if (matches.length) {
                const datePattern = {
                    year: null,
                    month: null,
                    shortMonth: null,
                    longMonth: null,
                    day: null,
                    hour: 0,
                    minute: 0,
                    second: 0,
                    ampm: null,
                    value: '',
                };

                if (matches[0].index > 0) {
                    datePattern.value += '.*?';
                }

                for (const [k, match] of Object.entries(matches)) {
                    const key = Number(k);

                    const {group, pattern} = DateTime.formatPatterns(match[0], this.lang);

                    datePattern[group] = key + 1;
                    datePattern.value += pattern;

                    datePattern.value += '.*?'; // any delimiters
                }

                const dateRegex = new RegExp(`^${datePattern.value}$`);

                if (dateRegex.test(value)) {

                    const date = DateTime.parseDateTime(event.target.value, this.format, this.lang)
                    picker.setDate(date)
                    picker.gotoDate(date)
                    picker.trigger('select', {detail: {event: event, picker: picker}})
                }
            }
        })

        if (window.initialized.easepick === undefined) window.initialized.easepick = [];
        window.initialized.easepick[this.element.id] = picker;
    }

    _getPlugins() {
        const plugins = [
            AmpPlugin,
            LockPlugin,
        ]

        if (this.keyboards) {
            plugins.push(KbdPlugin)
        }

        if (this.times) {
            plugins.push(TimePlugin)
        }

        if (this.ranges) {
            plugins.push(RangePlugin)

            if (this.presets) {
                plugins.push(PresetPlugin)
            }
        }

        return plugins;
    }

    /**
     * @returns IPickerConfig
     * @private
     */
    _getOptions() {
        return {
            element: this.element,
            plugins: this._getPlugins(),
            css: css_picker + css_picker_tabler,

            firstDay: this.firstDay,
            lang: this.lang,
            date: this.date,
            format: this.format,
            grid: this.grid,
            calendars: this.calendars,
            readonly: this.readonly,
            autoApply: this.autoApply,
            zIndex: 10,
            inline: this.inline,
            header: this.header,
            locale: this.locale,
            // ** Plugin : Range
            RangePlugin: {
                elementEnd: this.elementEnd,
                startDate: this.startDate,
                endDate: this.endDate,
                repick: this.repick,
                strict: this.strict,
                delimiter: this.delimiter,
                tooltip: this.tooltip,
                locale: this.rangeLocale,
            },
            // ** Plugin : AMP
            AmpPlugin: {
                dropdown: this.dropdown,
                resetButton: this.resetButton,
                darkMode: this.darkMode,
                weekNumbers: this.weekNumbers,
                locale: this.ampLocale,
            },
            // ** Plugin : KDB
            KdbPlugin: {
                unitIndex: this.unitIndex,
                dayIndex: this.dayIndex,
            },
            // ** Plugin : Lock
            LockPlugin: {
                minDate: this.minDate,
                maxDate: this.maxDate,
                minDays: this.minDays,
                maxDays: this.maxDays,
                selectForward: this.selectForward,
                selectBackward: this.selectBackward,
                presets: this.presets,
                inseparable: this.inseparable,
                filter: (date, picked) => {
                    if (this.allowedDays) {
                        return this.allowedDays.includes(date.format(this.format)) === false
                    } else if (this.disallowedDays) {
                        return this.disallowedDays.includes(date.format(this.format)) === true
                    }

                    return false
                },
            },
            // ** Plugin : Preset
            PresetPlugin: {
                customPreset: this.getCustomPreset(),
                customLabels: this.customLabels,
                position: this.position,
            },
            // ** Plugin : Time
            TimePlugin: {
                seconds: this.seconds,
                stepHours: this.stepHours,
                stepMinutes: this.stepMinutes,
                stepSeconds: this.stepSeconds,
                format12: this.format12,
            }
        }
    }

    getCustomPreset() {
        const ranges = []
        this.customPreset.forEach((dates) => {
            const [start, end] = dates
            ranges.push([
                DateTime.parseDateTime(start, this.format, this.lang),
                DateTime.parseDateTime(end, this.format, this.lang)
            ])
        })

        const preset = {}
        Object.values(this.customLabels).forEach((label, key) => {
            preset[label] = ranges[key];
        });

        return preset
    }
}
