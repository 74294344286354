export class ContextMenuItem {
    constructor() {
        this.disabled = false;
        this.active = false;
        this.badge = {
            text: null,
            backgroundColor: 'green'
        };
    }
    _initMenuItem(el) {
        if (this.class)
            el.classList.add(...this.class.split(' '));
        if (this.disabled)
            el.classList.add('disabled');
        if (this.active)
            el.classList.add('active');
        el.classList.add('dropdown-item');
    }
    _setItemContent(el, name, setInnerHTML = true) {
        var _a;
        if (setInnerHTML) {
            el.innerHTML = name;
        }
        if ((_a = this.badge.text) !== null && _a !== void 0 ? _a : false) {
            const badge = document.createElement('span');
            badge.classList.add('badge', 'badge-sm', 'text-uppercase', 'ms-2');
            badge.classList.add('bg-' + this.badge.backgroundColor);
            badge.innerHTML = this.badge.text;
            el.appendChild(badge);
        }
    }
}
