// Import fonction modules de Allsoftware Symfony Bundle
import * as modules from '../lib/allsoftware-symfony-bundle/assets/js/import_modules'

// Import du style de l'Application
import './styles/app.scss'

// Initialisation de Stimulus
import './bootstrap'

/**
 * Init toute la page
 * Ne pas faire si SWUP !
 */
modules.init(document.body);

/**
 * Init des elements depuis sélecteurs SWUP
 * A ne faire que si SWUP est utilisés
 */
// document.body
//     .querySelectorAll('#swup_sidebar, #swup_header, #swup_page_header, #swup')
//     .forEach((dom) => {
//         modules.init(dom)
//     })
