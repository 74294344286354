import {Placement} from "../TablerToast";

class TablerToast {
    /** @type {?HTMLDivElement} */
    element = null;
    /** @type {?TablerToastContainer} */
    container = null;
    /** @type {Placement} */
    placement = Placement.BottomRight;

    /** @type {?string} */
    bgColor = null;
    /** @type {?string} */
    imageUrl = null;
    /** @type {?string} */
    title = null;
    /** @type {?string} */
    textTime = "à l'instant";

    /** @type {?string} */
    body = null;

    /** @type {?Toast} */
    bsToast = null;
    /** @type {boolean} */
    animation = true;
    /** @type {boolean} */
    autohide = true;
    /** @type {int} */
    delay = 5000;

    /**
     * @param {string} body
     * @param {?string} title
     * @param {?string} textTime
     * @param {?Placement} placement
     */
    constructor(body, title = null, textTime = null, placement = null) {
        this.body = body;
        this.title = title || this.title;
        this.textTime = textTime || this.textTime;

        if (placement) this.placement = placement;
    }

    _create() {
        const toast = document.createElement('div');
        toast.classList.add('toast');
        if (this.bgColor) toast.classList.add('bg-' + this.bgColor)
        if (!this.container) toast.classList.add('position-fixed', 'mb-3', ...this.placement.toString().split(' '));
        toast.setAttribute('role', 'alert');
        toast.setAttribute('aria-live', 'assertive');
        toast.setAttribute('aria-atomic', 'true');
        toast.dataset.bsAutohide = this.autohide ? 'true' : 'false';
        toast.dataset.bsToggle = 'toast';

        const toastHeader = document.createElement('div');
        toastHeader.classList.add('toast-header');
        if (this.imageUrl) {
            const span = document.createElement('span');
            span.classList.add('avatar', 'avatar-xs', 'me-2');
            span.style.backgroundImage = `url(${this.imageUrl})`;
            toastHeader.appendChild(span);
        }

        const toastTitle = document.createElement('strong');
        toastTitle.classList.add('me-auto');
        toastTitle.textContent = this.title || '';
        toastHeader.appendChild(toastTitle);

        if (this.textTime) {
            const toastTime = document.createElement('small');
            toastTime.textContent = this.textTime;
            toastHeader.appendChild(toastTime);
        }

        const closeButton = document.createElement('button');
        closeButton.classList.add('ms-2', 'btn-close');
        closeButton.setAttribute('type', 'button');
        closeButton.setAttribute('data-bs-dismiss', 'toast');
        closeButton.setAttribute('aria-label', 'Close');
        toastHeader.appendChild(closeButton);

        toast.appendChild(toastHeader);

        const toastBody = document.createElement('div');
        toastBody.classList.add('toast-body');
        toastBody.innerHTML = this.body;

        toast.appendChild(toastBody);

        const progress = document.createElement('div');
        progress.classList.add('progress', 'card-progress');

        const progressBar = document.createElement('div');
        progressBar.classList.add('progress-bar');
        progressBar.style.width = '0%';
        progressBar.setAttribute('role', 'progressbar');
        progressBar.setAttribute('aria-valuenow', '0');
        progressBar.setAttribute('aria-valuemin', '0');
        progressBar.setAttribute('aria-valuemax', '100');

        toast.addEventListener('shown.bs.toast', () => {
            if (this.delay < 1000) return;

            const interval = this.delay / 1000;
            progressBar.style.transition = `none`;
            progressBar.style.transition = `linear ${interval.toFixed(2)}s`;
            progressBar.style.width = '100%';
        });

        progress.appendChild(progressBar);

        toast.appendChild(progress);

        this.element = toast;
    }

    _init() {
        this._create();

        if (this.container) {
            this.container.element.appendChild(this.element);
        } else {
            document.body.appendChild(this.element);
        }

        this.bsToast = new bootstrap.Toast(this.element, {
            animation: this.animation,
            autohide: this.autohide,
            delay: this.delay
        });
    }

    show() {
        if (!this.element) this._init();
        this.bsToast.show();
    }

    hide() {
        this.bsToast.hide();
    }

    dispose() {
        this.bsToast.dispose();
    }
}


export {TablerToast};
