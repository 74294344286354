import { Controller } from '@hotwired/stimulus';
import TablerModal from "../js/modules/TablerModal";
export default class default_1 extends Controller {
    openModal(e) {
        const button = e.target;
        TablerModal.form(this.formUrlValue, {
            title: this.titleValue,
            autoHide: this.autoHideValue,
            refreshOnSuccess: this.refreshOnSuccessValue,
        });
    }
}
// Params de `stimulus_controller('modal-form')`
default_1.values = {
    formUrl: String,
    title: String,
    refreshOnSuccess: { type: Boolean, default: true },
    autoHide: { type: Boolean, default: false }, // Flag pour cacher la modale à la validation du formulaire
};
