import { Controller } from '@hotwired/stimulus';
import { fieldRequired } from '../../lib/allsoftware-symfony-bundle/assets/ts/utils';
export default class default_1 extends Controller {
    notifyNewTargetConnected() {
        this.onNotifyNewChanged();
    }
    onNotifyNewChanged() {
        if (this.hasPasswordsTarget && this.hasNotifyNewTarget) {
            this.passwordsTarget.querySelectorAll('input')
                .forEach((input) => {
                input.disabled = this.notifyNewTarget.checked;
                fieldRequired(input, this.notifyNewTarget.checked === false);
            });
        }
    }
}
default_1.targets = [
    'email',
    'fullName',
    'passwords',
    'notifyNew',
    'roleAdmin',
];
