var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Modal } from 'bootstrap';
import { stringToHTML } from '../../ts/utils';
const CST_Spinner = `
    <div class='d-flex justify-content-center'>
      <div class='spinner-border' style='width: 3rem; height: 3rem;' role='status'>
        <span class='visually-hidden'>Chargement...</span>
      </div>
    </div>
`;
const CST_Footer = `
    <button type='button' class='btn w-100' data-bs-dismiss='modal'>OK</button>
`;
const CST_modal = `
<div class='modal fade' tabindex='-1' role='dialog'>
    <div class='modal-dialog' role='document'>
        <div class='modal-content'>
            <div class='modal-status'></div>
            <button class='btn-close' type='button' data-bs-dismiss='modal' aria-label='Close'></button>
            <div class='modal-header'>
                <h4 class='modal-title'></h4>
            </div>
            <div class='modal-body'></div>
            <div class='modal-footer'>
                ${CST_Footer}
            </div>
            <div class='modal-body modal-waiting' hidden>
                ${CST_Spinner}
            </div>
        </div>
    </div>
</div>
`;
export default class TablerModal {
    constructor(title = '', body = '', destroyOnClosed = true) {
        this.title = '';
        this.header = true;
        this.headerExtraClass = [];
        this.status = null;
        this.rawModalHTMLContent = '';
        this.rawModalHTMLHeader = '';
        this.body = '';
        this.bodyExtraClass = [];
        this._bodyRendered = false;
        this.footer = undefined;
        this.footerExtraClass = [];
        this.modalId = null;
        this.attr = {};
        this.modalDialogExtraClass = '';
        this.modalExtraClass = [];
        this.modalSize = 'lg';
        this.modalEffect = 'fade';
        this.modalContainer = 'body';
        this.removeOnClosed = true;
        this.modal = null;
        /** @type Modal */
        this.bsModal = null;
        this.title = title;
        this.body = body;
        this.removeOnClosed = destroyOnClosed;
    }
    static fetch(title, url, isForm = false) {
        return new Promise((resolve, reject) => {
            const tablerModal = new TablerModal(title);
            if (isForm) {
                /** @deprecated Use form() instead! */
                tablerModal.footer = `         
                    <button type='button' class='btn btn-ghost-dark me-auto' data-bs-dismiss='modal'>
                        <i class='fa fa-times icon'></i>Annuler
                    </button>
                    <button type='submit' class='btn btn-success'>
                        <i class='fa fa-check icon'></i>Valider
                    </button>     
                `;
            }
            fetch(url.toString(), {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
                .then(response => response.text())
                .then((html) => {
                tablerModal.body = html;
                resolve(tablerModal);
            })
                .catch((e) => reject(e));
        });
    }
    static form(url, options = {}) {
        const { title = '', modalSize = 'lg', autoHide = true, refreshOnSuccess = true, autoFocus = true, submitOnEnter = true, extraFormData = {} } = options;
        return new Promise((resolve, reject) => {
            const tablerModal = new TablerModal(title);
            tablerModal.modalSize = modalSize;
            tablerModal.modalDialogExtraClass = 'modal-dialog-centered';
            tablerModal.footer = `         
                <a href="#" class="btn btn-link link-muted" data-bs-dismiss="modal">
                    Annuler
                </a>
                <button type='submit' class='btn btn-success ms-auto'>
                    <i class='fa fa-check icon'></i>Valider
                </button>     
            `;
            tablerModal.show();
            tablerModal.waiting(true);
            const handleHtmlResponse = (htmlString) => {
                if (submitOnEnter) {
                    const parser = new DOMParser();
                    const html = parser.parseFromString(htmlString, 'text/html');
                    const submitInput = document.createElement("input");
                    submitInput.name = "submit";
                    submitInput.type = "submit";
                    submitInput.hidden = true;
                    Array.from(html.forms).forEach(form => form.appendChild(submitInput));
                    tablerModal._setBody(html.documentElement.outerHTML);
                }
                else {
                    tablerModal._setBody(htmlString);
                }
                tablerModal.waiting(false);
                tablerModal.show();
                setTimeout(() => {
                    if (autoFocus) {
                        let firstInput;
                        const invalidInputs = tablerModal.modal.querySelectorAll('input.is-invalid');
                        if (invalidInputs.length > 0) {
                            firstInput = invalidInputs[0];
                        }
                        else {
                            firstInput = tablerModal.modal.querySelector('input:not([disabled]):not([readonly]):not(.disabled)');
                        }
                        if (firstInput) {
                            firstInput.focus();
                            firstInput.select();
                        }
                    }
                }, 100);
                tablerModal.modal.querySelectorAll('form').forEach((form) => {
                    form.onsubmit = (e) => submitForm(e);
                });
            };
            const submitForm = (event) => __awaiter(this, void 0, void 0, function* () {
                // Bloque l'événement "Submit" par défaut.
                // Car on ne souhaite pas de reload de la page en entière.
                event.preventDefault();
                const form = tablerModal.modal.querySelector('form');
                // Validation des champs requis du formulaire
                if (!form.reportValidity()) {
                    return;
                }
                // Affichage de loading
                tablerModal.waiting(true);
                // Récupération de l'URL action du formulaire si existant, sinon lien URL de la popup
                const action = form.hasAttribute('action') ? form.action : url;
                // Remplissage des données du formulaire
                const formData = new FormData(form);
                // Ajout des données extra pour le formulaire
                for (const [name, value] of Object.entries(extraFormData)) {
                    formData.append(name, value.toString());
                }
                // Dispatch de l'event de submit
                form.dispatchEvent(new CustomEvent('modal:form:submit', { detail: { formData: formData } }));
                fetch(action, {
                    method: form.method,
                    body: formData,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
                    .then(response => {
                    if (response.ok) {
                        return response.text();
                    }
                    return Promise.reject(response);
                })
                    .then(html => {
                    if (autoHide) {
                        // Fermeture de la modale
                        tablerModal.close();
                    }
                    else {
                        // Afficher le retour dans le body
                        tablerModal._setBody(html);
                    }
                    form.dispatchEvent(new CustomEvent('modal:form:submitted'));
                    if (refreshOnSuccess) {
                        // Pas de 'location.reload()' car une popup "alert" apparait sur le navigateur !
                        window.location.href = window.location.href.split('#')[0];
                    }
                })
                    .catch((e) => {
                    console.error(e);
                    e.text().then((htmlString) => {
                        handleHtmlResponse(htmlString);
                    });
                });
            });
            fetch(url.toString(), {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
                .then(response => response.text())
                .then((htmlString) => {
                handleHtmlResponse(htmlString);
                const submitButton = tablerModal.modal.querySelector('button[type="submit"]');
                submitButton.onclick = (e) => submitForm(e);
                resolve(tablerModal);
            })
                .catch((e) => reject(e));
        });
    }
    /**
     * Affiche la modale
     */
    show() {
        return new Promise((resolve) => {
            if (this.modal === null) {
                this._config();
            }
            this.bsModal.show();
            resolve(this);
        });
    }
    /**
     * Ferme la modal
     */
    close() {
        if (this.modal !== null) {
            this.bsModal.hide();
        }
    }
    waiting(wait = true) {
        this._setBodyVisible(wait === false);
        this._setFooterVisible(wait === false);
        this.modal.querySelector('.modal-waiting').hidden = wait === false;
    }
    /**
     * Affiche une modale de confirmation
     * Note : fonctionne avec une 'Promise'
     */
    confirm(options = {}) {
        return new Promise((resolve) => {
            const { title = 'Attention', question = 'Confirmez-vous l\'action ?', cancelLabel = 'Annuler', confirmLabel = 'Valider', } = options;
            this.modalDialogExtraClass = 'modal-dialog-centered';
            this.status = this.status || 'warning';
            this.modalSize = 'sm';
            this.header = false;
            this.bodyExtraClass = ['text-center', 'py-4'];
            const body = `
                <i class='fas fa-question mb-2 text-warning fa-4x'></i>
                <h3>${title}</h3>
                <div class='text-muted'>${question}</div>
            `;
            this._setBody(body);
            const footer = `
            <div class="w-100">
                <div class="row">
                    <div class="col">
                        <button type='button' class='btn w-100 btn-ghost btn_cancel'>${cancelLabel}</button>
                    </div>
                    <div class="col">
                        <button type='button' class='btn w-100 btn-warning ms-auto btn_confirm'>${confirmLabel}</button>
                    </div>
                </div>
            </div>               
                
            `;
            this._setFooter(footer);
            this.show();
            this.modal.querySelector('.btn_confirm').onclick = () => {
                resolve(true);
                this.close();
            };
            this.modal.querySelector('.btn_cancel').onclick = () => {
                resolve(false);
                this.close();
            };
            this.modal.addEventListener('hide.bs.modal', () => {
                resolve(false);
            });
        });
    }
    /**
     * Affiche une modale d'avertissement
     * Note : fonctionne avec une 'Promise'
     */
    warn(message = 'Action impossible !', title = 'Action impossible !') {
        return new Promise((resolve) => {
            this.modalDialogExtraClass = 'modal-dialog-centered';
            this.status = this.status || 'warning';
            this.modalSize = 'sm';
            // modal.title = this.title || `<i class="fas fa-exclamation-triangle"></i> Erreur !`
            this.bodyExtraClass = ['text-center', 'py-4'];
            const body = `
                <i class='fas fa-exclamation-triangle mb-2 text-warning fa-4x'></i>
                <h3>${title}</h3>
                <div class='text-muted'>${message}</div>
            `;
            this._setBody(body);
            this._setFooter(CST_Footer);
            this.show();
            this.modal.addEventListener('hide.bs.modal', (event) => {
                resolve(true);
            });
        });
    }
    static error(options = {}) {
        return (new this()).error(options);
    }
    /**
     * Affiche une modale d'erreur
     */
    error(options = {}) {
        return new Promise((resolve) => {
            const { title = 'Erreur !', message = 'Une erreur s\'est produite..', } = options;
            this.modalDialogExtraClass = 'modal-dialog-centered';
            this.status = this.status || 'danger';
            this.modalSize = 'sm';
            this.header = false;
            this.bodyExtraClass = ['text-center', 'py-4'];
            const body = `
                <i class='fas fa-exclamation-triangle mb-2 text-danger fa-4x'></i>
                <h3>${title}</h3>
                <div class='text-muted'>${message}</div>
            `;
            this._setBody(body);
            this._setFooter(CST_Footer);
            this.show();
            this.modal.addEventListener('hide.bs.modal', (event) => {
                resolve(true);
            });
        });
    }
    static ask(options) {
        return (new this()).ask(options);
    }
    /**
     * Affiche une modale avec un champ de saisie
     */
    ask(options) {
        const { label, type = 'text', value = '', cancel_label = 'Annuler', confirm_label = 'Valider' } = options;
        return new Promise((resolve) => {
            this.modalSize = this.modalSize || 'lg';
            this.title = this.title || `Saisissez les informations`;
            let input;
            if (type === 'datetime') {
                input = `<input type='text' class='single-date form-control' data-widget='daterangepicker' value='${value}'>`;
            }
            else {
                input = `<input type='text' class='form-control' required value='${value}'>`;
            }
            this.body = `                
                <form>
                    <div class='mb-3'>
                        <label class='form-label'>${label}</label>
                        ${input}
                    </div>
                    <div class='row'>
                        <div class='col'>
                            <button type='button' class='btn btn-ghost-primary btn_cancel' data-bs-dismiss='modal'><i class='fa fa-times icon'></i>${cancel_label}</button>
                        </div>
                        <div class='col-auto'>
                            <button type='submit' class='btn btn-success btn_confirm ms-auto'><i class='fa fa-check icon'></i>${confirm_label}</button>                
                        </div>
                    </div>
                </form>
            `;
            this.footer = false;
            this.show().then(() => {
                this.modal.querySelector('input').focus();
            });
            this.modal.querySelector('form').onsubmit = (e) => {
                e.preventDefault();
                e.stopPropagation();
                const form = this.modal.querySelector('form');
                if (form.reportValidity()) {
                    const value = form.querySelector('input').value;
                    resolve(value);
                }
            };
            this.modal.querySelector('.btn_cancel').onclick = () => {
                resolve(null);
            };
        });
    }
    /**
     * Copie une nouvelle modale depuis la modale "source" : CST_BaseModal_Pointer
     */
    _create() {
        var _a;
        const modal = stringToHTML(CST_modal);
        modal.id = (_a = this.modalId) !== null && _a !== void 0 ? _a : 'modal_' + Date.now();
        const container = document.querySelector(this.modalContainer);
        if (!container) {
            throw new Error('Unable to find modalContainer: ' + this.modalContainer);
        }
        container.append(modal);
        this.modal = modal;
        return this;
    }
    /**
     * Configure la modale en fonction de ses paramètres
     */
    _config() {
        if (this.modal === null) {
            this._create();
        }
        if (!!this.rawModalHTMLContent) {
            this.modal.querySelector('.modal-content').innerHTML = this.rawModalHTMLContent;
        }
        else {
            /** TablerModal config */
            for (const [key, value] of Object.entries(this.attr)) {
                this.modal.setAttribute(key, value);
            }
            this.modal.classList.remove(...this.modal.classList);
            this.modal.classList.add('modal', 'modal-js', this.modalEffect);
            if (!!this.modalExtraClass) {
                this.modal.classList.add(...this.modalExtraClass);
            }
            /** TablerModal dialog */
            const modalDialog = this.modal.querySelector('.modal-dialog');
            modalDialog.classList.add('modal-' + this.modalSize);
            if (!!this.modalDialogExtraClass) {
                modalDialog.classList.add(this.modalDialogExtraClass);
            }
            /** Status */
            const modalStatus = this.modal.querySelector('.modal-status');
            if (this.status) {
                modalStatus.classList.add('bg-' + this.status);
            }
            else {
                modalStatus.remove();
            }
            /** Header */
            const modalHeader = this.modal.querySelector('.modal-header');
            if (!!this.rawModalHTMLHeader) {
                // Remplace modal-header (modal template) avec HTML présent dans 'rawModalHTMLHeader'
                modalHeader.innerHTML = this.rawModalHTMLHeader;
            }
            else if (this.header === false) {
                this.modal.querySelector('.modal-header').remove();
            }
            else {
                if (!!this.headerExtraClass) {
                    modalHeader.classList.add(...this.headerExtraClass);
                }
                this.modal.querySelector('.modal-header').appendChild(this.modal.querySelector('.btn-close'));
                /** Title */
                this.modal.querySelector('.modal-title').innerHTML = this.title;
            }
            /** Body */
            this._setBody(this.body);
            /** Footer */
            if (this.footer !== undefined) {
                const modalFooter = this.modal.querySelector('.modal-footer');
                if (typeof this.footer === 'string') {
                    modalFooter.innerHTML = this.footer;
                    if (!!this.footerExtraClass) {
                        modalFooter.classList.add(...this.footerExtraClass);
                    }
                }
                else {
                    modalFooter.remove();
                }
            }
        }
        this._bodyRendered = true;
        this.bsModal = new Modal(this.modal);
        this._initEvents();
        return this;
    }
    _setBodyVisible(visible) {
        if (this._bodyRendered) {
            this.modal.querySelector('.modal-body').hidden = visible === false;
        }
    }
    _setFooterVisible(visible) {
        if (this._bodyRendered && this.footer) {
            this.modal.querySelector('.modal-footer').hidden = visible === false;
        }
    }
    _setBody(content) {
        if (this.modal) {
            const modalBody = this.modal.querySelector('.modal-body');
            modalBody.innerHTML = content;
            if (!!this.bodyExtraClass) {
                modalBody.classList.add(...this.bodyExtraClass);
            }
            if (this._bodyRendered) {
                const event_initializeDom = new CustomEvent('dom:module:init', { detail: { dom: this.modal } });
                document.dispatchEvent(event_initializeDom);
            }
        }
        else {
            this.body = content;
        }
    }
    _setFooter(content) {
        if (this.modal) {
            const modalFooter = this.modal.querySelector('.modal-footer');
            modalFooter.innerHTML = content;
        }
        else {
            this.footer = content;
        }
    }
    /**
     * Initialise les évènements de la modale :
     * - Chargement des plugins
     * - Suppression HTML de la modale en fonction de 'removeOnClosed'
     */
    _initEvents() {
        this.modal.addEventListener('show.bs.modal', (event) => {
            const event_initializeDom = new CustomEvent('dom:module:init', { detail: { dom: this.modal } });
            document.dispatchEvent(event_initializeDom);
        });
        this.modal.addEventListener('hidden.bs.modal', (event) => {
            if (this.removeOnClosed) {
                this.modal.remove();
            }
        });
    }
}
