import {Controller} from '@hotwired/stimulus';

export const CST_DefaultMaxItemPerPages = 15;

export default class PaginatorJs extends Controller {

    static values = {
        maxItemPerPage: {type: Number, default: -1}, // Nombre max d'éléments par page
        paginatorCountText: String, // Modèle pour les infos de pagination avec '%START%', '%END%', '%TOTAL%'
    }

    static targets = [
        'labelDetails', // HTML element qui contient 'labelDetails'
        'inputItemPerPages', // HTML select indiquant le nombre d'éléments par page
        'inputSearch', // HTML input permettant la saisie de recherche
    ];

    get maxItemPerPage() {
        return this.maxItemPerPageValue
    }

    get paginatorCountText() {
        return this.paginatorCountTextValue
    }

    get labelDetails() {
        return this.labelDetailsTarget
    }

    get inputItemPerPages() {
        return this.inputItemPerPagesTarget
    }

    get inputSearch() {
        return this.inputSearchTarget
    }

    /**
     * Vérifie les prérequis du module
     * @private
     */
    _abortIfMissingRequiredSkeleton() {
        if (this.table === null) {
            throw new Error("'paginator-js' should have a <table>!");
        }

        // if (this.thead === null) {
        //     throw new Error("<table> should have a <thead> child!");
        // }

        if (this.tbody === null) {
            throw new Error("<table> should have a <tbody> child!");
        }

        if (this.pagination === null) {
            throw new Error("`paginator-js` should have a `.pagination` to handle page navigation!");
        } else if (!(this.pagination instanceof HTMLUListElement)) {
            throw new Error("`.pagination` should be of type <ul>!");
        }

        if (this.pageItemSample === null) {
            throw new Error("`.pagination` should have a <li class='page-item sample'> item page child for sample!");
        } else if (this.pageItemPrev === null) {
            throw new Error("`.pagination` should have a <li class='page-item prev'> PREV item page child!");
        } else if (this.pageItemNext === null) {
            throw new Error("`.pagination` should have a <li class='page-item next'> NEXT item page child!");
        }
    }

    /** @return {HTMLTableElement|null} */
    get table() {
        return this.element.querySelector('table');
    }

    get thead() {
        return this.table.querySelector('thead');
    }

    get tbody() {
        return this.table.querySelector('tbody');
    }

    /**
     * @returns {HTMLTableRowElement[]}
     */
    get items() {
        const allItems = this.allItems;

        if (this.searchValue === null) {
            return Array.from(allItems.values())
        }

        const search = this.searchValue.toLowerCase();

        const items = [];
        allItems.forEach(tr => {
            let match = false;
            tr.querySelectorAll('td').forEach(td => {
                if (match) return false;
                match = td.innerText.toLowerCase().includes(search);
            });

            if (match) items.push(tr);
        });

        return items;
    }

    get hasItems() {
        let items = [];
        this.allItems.forEach((tr) => {
            /** @var tr HTMLTableRowElement */
            const colspan = tr.querySelector('td').getAttribute('colspan')
            if (colspan === null || parseInt(colspan) < 80) {
                items.push(tr)
            }
        });

        return items.length > 0;
    }

    /**
     * @returns {NodeListOf<HTMLTableRowElement>}
     */
    get allItems() {
        return this.tbody.querySelectorAll('tr');
    }

    /** @return {number} */
    get totalItems() {
        return this.items.length;
    }

    /** @return {number} */
    get maxItems() {
        if (!!this.inputItemPerPages.value) {
            const value = parseInt(this.inputItemPerPages.value)
            return value > 0 ? value : CST_DefaultMaxItemPerPages;
        } else {
            return this.maxItemPerPage > 0 ? this.maxItemPerPage : CST_DefaultMaxItemPerPages;
        }
    }

    /** @return {HTMLUListElement|null} */
    get pagination() {
        return this.element.querySelector('.pagination');
    }

    get filterBlock() {
        return this.element.querySelector('.paginator-js-filter');
    }

    get slidingBlock() {
        return this.element.querySelector('.paginator-js-sliding');
    }

    get pageItemPrev() {
        return this.pagination.querySelector('li.page-item.prev');
    }

    get pageItemNext() {
        return this.pagination.querySelector('li.page-item.next');
    }

    get pageItemSample() {
        return this.pagination.querySelector('li.page-item.sample');
    }

    get pageItems() {
        return this.pagination.querySelectorAll('li.page-item:not(.sample):not(.prev):not(.next)');
    }

    get currentPage() {
        return this._currentPage || 1;
    }

    set currentPage(page) {
        this._currentPage = +page || 1;
        this._currentPage = Math.min(Math.max(this._currentPage, 1), this.maxPages);
    }

    /**
     * Retourne le numéro du premier item de la page en cours
     * @return {number}
     */
    get itemCountStart() {
        return (this.currentPage - 1) * this.maxItems + 1;
    }

    /**
     * Retourne le numéro du dernier item de la page en cours
     * @return {number}
     */
    get itemCountEnd() {
        if (this.currentPage !== this.maxPages) {
            // Ce n'est pas la dernière page
            return (this.itemCountStart - 1 + this.maxItems);
        } else {
            // Dernière page
            return this.totalItems;
        }
    }

    get searchValue() {
        return this._searchValue || null;
    }

    set searchValue(value) {
        this._searchValue = value || null;
    }

    initialize() {
        super.initialize();

        this._abortIfMissingRequiredSkeleton();

        if (this.hasItems === false) {
            this.slidingBlock.hidden = true;
            this.filterBlock.hidden = true;
        }

        // ** Bind des events ** //
        this.pageItemPrev.onclick = (e) => this.clickPrevPage(e);
        this.pageItemNext.onclick = (e) => this.clickNextPage(e);
        if (this.inputItemPerPages) {
            this.inputItemPerPages.onchange = (e) => this.itemPerPagesChange(e);
        }
        if (this.inputSearch) {
            this.inputSearch.onkeyup = (e) => this.searchChange(e);
        }
        // ********************************** //

        this.currentPage = 1;

        this._initPaginator();
        this._paginate();
    }

    /**
     * Initialise le paginator (numéro de page) cliquable
     * @private
     */
    _initPaginator() {

        this.currentPage = 1;

        // Calcul du nombre de pages requis
        this.maxPages = Math.ceil(this.items.length / this.maxItems);

        this.pagination.hidden = true;

        this.pageItemSample.hidden = true;
        this.pageItems.forEach(el => el.remove());

        // Pour toutes les pages requises
        for (let i = 1; i <= this.maxPages; i++) {
            // ** Clone du sample
            /** @type HTMLTableRowElement */
            let pageItem = this.pageItemSample.cloneNode(true);
            pageItem.classList.remove('sample')
            pageItem.setAttribute('page', i.toString());
            // Bind de event click
            pageItem.onclick = (e) => this.clickPage(e);

            /** @type HTMLLinkElement */
            const pageLink = pageItem.querySelector('.page-link');
            pageLink.setAttribute('page', i.toString());
            pageLink.innerText = i.toString();

            // Ajout du bouton AVANT le bouton "precedent"
            this.pagination.insertBefore(pageItem, this.pageItemNext)
        }

        // Affiche les nouveaux boutons
        this.pageItems.forEach(el => el.hidden = false);

        this._highlightPaginator();

        // Affiche le paginator
        this.pagination.hidden = false;
    }

    /**
     * Ajoute l'effet `active` en fonction de la page en cour
     * @private
     */
    _highlightPaginator() {
        this.pageItems.forEach((el, i) => {
            el.classList.toggle('active', (i + 1) === this.currentPage);
        });

        // Si première page, désactive bouton "précédent".
        this.pageItemPrev.classList.toggle('disabled', this._currentPage === 1);
        // Si dernière page, désactive bouton "suivant".
        this.pageItemNext.classList.toggle('disabled', this._currentPage === this.maxPages);
    }

    /**
     * Affiche les items en fonction de la page en cours + recherche
     * @private
     */
    _paginate() {
        this.allItems.forEach(tr => tr.classList.add('d-none'));

        this.items.forEach((tr, i) => {
            if (Math.ceil((i + 1) / this.maxItems) === this.currentPage) {
                tr.classList.remove('d-none');
            }
        });

        this._updateLabelDetails();
    }

    _updateLabelDetails() {
        if (this.paginatorCountText) {
            if (this.labelDetails === false) {
                throw new Error('If value `paginatorCountText` is defined, target `labelDetails` of `paginator-js` is required !')
            }

            let paginatorCountText = this.paginatorCountText;
            // '%START%', '%END%', '%TOTAL%'
            paginatorCountText = paginatorCountText.replace('%START%', this.itemCountStart)
            paginatorCountText = paginatorCountText.replace('%END%', this.itemCountEnd)
            paginatorCountText = paginatorCountText.replace('%TOTAL%', this.totalItems)

            this.labelDetails.innerHTML = paginatorCountText;
        }
    }

    /**
     * Évènement click sur bouton numéro page
     * @param {Event} e
     */
    clickPage(e) {
        this._handleChangePage(e, +e.target.getAttribute('page'));
    }

    /**
     * Évènement click sur bouton page suivante
     * @param {Event} e
     */
    clickNextPage(e) {
        this._handleChangePage(e, this.currentPage + 1);
    }

    /**
     * Évènement click sur bouton page précédente
     * @param {Event} e
     */
    clickPrevPage(e) {
        this._handleChangePage(e, this.currentPage - 1);
    }

    /**
     * Permet de changer de page depuis un Event
     * @param {Event} e
     * @param {number} page
     * @private
     */
    _handleChangePage(e, page) {
        e.preventDefault();
        e.stopPropagation();

        if (this.currentPage === page) return;

        this.currentPage = page;

        if (this.currentPage !== page) return;

        this._highlightPaginator();
        this._paginate();
    }

    /**
     * Évènement de changement du nombre d'éléments par pages
     * @param {Event} e
     */
    itemPerPagesChange(e) {
        this._initPaginator();
        this._paginate();
    }

    /**
     * Évènement de recherche
     * @param {Event} e
     */
    searchChange(e) {
        this.searchValue = this.inputSearch.value;

        this._initPaginator();
        this._paginate();
    }
}
