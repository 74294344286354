import { Controller } from '@hotwired/stimulus';
import { init as init_JSModules } from "../js/import_modules.js";
export default class CardPaginator extends Controller {
    onAutoSubmit(customEvent) {
        if (this.autoSearchValue === false) {
            // Pas de reload souhaité
            return;
        }
        // Récupération du formulaire
        const form = customEvent.detail.form;
        // Validation des champs requis du formulaire
        if (!form.reportValidity()) {
            return;
        }
        this.element.style.opacity = "0.5";
        // Récupération de l'url ACTION du formulaire
        const url = new URL(form.action);
        // Force la page 1
        url.searchParams.set('page', '1');
        // Sérialise le formulaire
        const formData = new FormData(form);
        // Force le bouton "filtrer" en tant que cliqué
        formData.set(`${form.name}[filter_data]`, 'true');
        fetch(url, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.text())
            .then(html => {
            const parser = new DOMParser();
            const response_dom = parser.parseFromString(html, 'text/html');
            // Récupération de l'élément courant dans la réponse
            const cardPaginatorReloaded = response_dom.getElementById(this.element.id);
            // Remplace l'intérieur actuel par le contenu reçu
            this.element.innerHTML = cardPaginatorReloaded.innerHTML;
            // (Re)Init tous les modules JS (node_modules)
            init_JSModules(this.element);
            // Retour à l'opacité de base
            this.element.style.opacity = "1";
            // Suppression de la progressbar card
            const progressCard = form.closest('.card').querySelector('.card-progress');
            if (progressCard) {
                progressCard.remove();
            }
        });
    }
}
CardPaginator.values = {
    autoSearch: Boolean,
};
