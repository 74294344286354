import { Controller } from '@hotwired/stimulus';
export default class CodeInput extends Controller {
    connect() {
        super.connect();
        if (this.codeTarget.value.length < this.codeInputTargets.length) {
            this.codeInputTargets[this.codeTarget.value.length].focus();
        }
    }
    onCodeInputFocus(event) {
        const input = event.target;
        input.select();
    }
    onCodeInputInput(event) {
        // Print all inputs value in final authCode input
        let code = "";
        this.codeInputTargets.forEach((input) => code += input.value);
        this.codeTarget.value = code;
        const input = event.target;
        const i = this.codeInputTargets.indexOf(input);
        // If the input field has a character
        if (input.value.length === input.maxLength) {
            if (i + 1 < this.codeInputTargets.length) {
                // There is a next input field, focus it
                this.codeInputTargets[i + 1].focus();
            }
            else {
                // Last input, submit
                input.blur();
                input.form.submit();
            }
        }
    }
    onCodeInputKeydown(event) {
        const input = event.target;
        const i = this.codeInputTargets.indexOf(input);
        // If the input field is empty and Backspace is detected, and there is a previous input field, focus it
        if (input.value.length === 0 && event.key === "Backspace" && i > 0) {
            this.codeInputTargets[i - 1].focus();
        }
    }
    onCodeInputPaste(event) {
        const input = event.target;
        const i = this.codeInputTargets.indexOf(input);
        const inputs = this.codeInputTargets;
        const pastedValue = event.clipboardData.getData('text');
        pastedValue.substring(0, inputs.length).split('').forEach(function (letter, index) {
            if (i + index > inputs.length) {
                return;
            }
            const input = inputs[i + index];
            input.value = letter;
            input.dispatchEvent(new Event('input'));
        });
    }
}
CodeInput.targets = [
    'code',
    'codeInput',
];
