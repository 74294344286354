export default class TablerButtonLoader {
    constructor(element, options = {}) {
        this.options = {
            color: 'white'
        };
        if (element.attributes['data-no-loader']) {
            return;
        }
        if (element instanceof HTMLButtonElement) {
            element.addEventListener('click', e => this._onClick(e));
        }
        else if (element instanceof HTMLFormElement) {
            element.addEventListener('submit', e => this._onSubmit(e));
        }
        else {
            throw new Error('TablerButtonLoader: element must be HTMLFormElement or HTMLButtonElement');
        }
        this.options = Object.assign(Object.assign({}, this.options), options);
    }
    _onClick(e) {
        const button = e.currentTarget;
        this._createSpinner(button);
    }
    _onSubmit(e) {
        const form = e.currentTarget;
        if (form.checkValidity()) {
            form.querySelectorAll('button[type="submit"]').forEach(button => {
                this._createSpinner(button);
            });
        }
    }
    _createSpinner(button) {
        button.style.width = button.clientWidth + 'px';
        button.style.height = button.clientHeight + 'px';
        button.classList.add('disabled');
        const spinner = document.createElement('div');
        spinner.classList.add('spinner-border', 'spinner-border-sm', `text-${this.options.color}`);
        spinner.setAttribute('role', 'status');
        button.innerHTML = spinner.outerHTML;
    }
}
