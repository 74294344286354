import {Controller} from '@hotwired/stimulus';
import $ from "jquery";
import {Tab} from "bootstrap";
import TablerModal from "../js/modules/TablerModal";
import {useDispatch} from "stimulus-use";

export default class Bootstrap5Tabs_controller extends Controller {

    $nav = undefined;
    $tabContent = undefined;

    connect() {
        this._checkContainer();

        useDispatch(this);
    }

    /**
     * async car la fonction utilise une fonction asynchrone: await fetch()
     * @param {Event} event
     */
    async addTabFromHTMLAttributes(event) {
        const target = event.target.nodeName.toLowerCase() === "i" ? event.target.parentElement : event.target;

        const id = target.getAttribute('data-new-tab-id') ?? 'tab_new_' + Date.now();
        const title = target.getAttribute('data-new-tab-title') ?? 'Nouvel onglet';
        const content_url = target.getAttribute('data-new-tab-content-url') ?? null;
        const auto_select = target.getAttribute('data-new-tab-auto-select') ?? true;
        const containerAttr = JSON.parse(target.getAttribute('data-new-tab-container-attr')) ?? null;

        let bootstrapTabData = new BootstrapTabData(id, title)
        if (containerAttr) bootstrapTabData.containerAttr = containerAttr;
        if (content_url) bootstrapTabData.contentUrl = content_url;

        await this.addTab(bootstrapTabData, auto_select);
    }

    /**
     * Permet l'ajout d'un onglet
     * @param {BootstrapTabData} bootstrapTabData
     * @param auto_select
     */
    async addTab(bootstrapTabData, auto_select = true) {
        if (this.$tabContent.children('' + bootstrapTabData.id).length > 0) {
            throw new Error(`[BootstrapTabs] Tab '${bootstrapTabData.id}' already exist !`);
        }

        // **** Ajout du lien dans le nav *****
        const $a = $('<a></a>')
            .attr('href', `#${bootstrapTabData.id}`)
            .attr('data-bs-toggle', 'tab')
            .addClass(['nav-link', bootstrapTabData.linkClass])
            .html(bootstrapTabData.title)
        ;

        if (bootstrapTabData.removable) {
            this._plugRemovable(bootstrapTabData, $a)
        }

        const $li = $('<li></li>')
            .addClass(['nav-item', bootstrapTabData.navClass])
            .append($a)
        ;

        // **** Ajout du tab content *****
        if (bootstrapTabData.contentUrl) await this._getTabContentAJAX(bootstrapTabData)

        const $tab = $('<div></div>')
            .attr('id', bootstrapTabData.id)
            .addClass('tab-pane ' + bootstrapTabData.contentClass)
            .html(bootstrapTabData.content)
        ;

        this.$tabContent.append($tab);
        this.$nav.append($li);

        const dom = $tab[0];

        const event_initializeDom = new CustomEvent("dom:module:init", {detail: {dom: dom}});
        document.dispatchEvent(event_initializeDom);

        if (auto_select) {
            new Tab($a).show();
        }

        this.dispatch('add:success', {detail: {dom: dom}});
    }

    /**
     * Permet de supprimer un onglet
     * @param {string} id
     */
    removeTab(id) {

        if (this.$nav.find('a.nav-link.active').attr('href') === '#' + id) {
            // Onglet à supprimer est celui actif,
            // Retourne sur le premier onglet
            const $firstTab = this.$nav.find('a.nav-link:not(.hidden):not(.disabled)').first();
            new Tab($firstTab).show();
        }

        this.$nav.find(`a[href="#${id}"]`).parent('li').remove();
        this.$tabContent.find('#' + id).remove();

        this.dispatch('remove:success');
    }


    /**
     * Fonction appelée pour SUBMIT un formulaire,
     * xxxxx->bootstrap5-tabs#submitForm
     */
    async submitForm(event) {
        event.preventDefault();
        event.stopPropagation();

        /** @type {HTMLFormElement} */
        let form;
        // Récupération du form associé
        if (event.target instanceof HTMLFormElement) {
            // Event vient de submit
            form = event.target;
        } else if (event.target instanceof HTMLButtonElement) {
            // Event vient de click
            form = event.target.closest('form');
        }

        // Verification si le formulaire est valide (require input)
        if (form.reportValidity()) {
            // Récupération du nom du bouton cliqué
            const clickedButtonName = event.params.clickedButtonName ?? undefined;

            try {
                // Création de la requête AJAX
                const req = new XMLHttpRequest();
                req.open(form.method, form.action, true);
                // Set du flag isXmlRequest
                req.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

                // Recuperation des données du formulaire
                const data = new FormData(form);

                // Si un bouton a été clické
                if (clickedButtonName) {
                    // Set de sa valeur à "" et non null.
                    // Permet d'avoir l'info isClicked() à vrai côté symfony.
                    data.set(form.name + clickedButtonName, "");
                }

                // ** Envoi de l'AJAX ** //
                await req.send(data);
                // ********************* //

                this.dispatch('submit:success');

            } catch (e) {
                console.error(e);

                // Change le formulaire avec le retour d'erreur.
                form.parent.innerHTML = e.responseText;
                this.dispatch('submit:error');
            }
        }
    }


    /**
     * Récupère le contenu d'un onglet en AJAX
     * @param {BootstrapTabData} bootstrapTabData
     * @private
     */
    async _getTabContentAJAX(bootstrapTabData) {
        // Récupération du contenu en AJAX
        const content = await $.ajax(bootstrapTabData.contentUrl)
            .fail(e => {
                alert(`Error while loading tab content from '${bootstrapTabData.contentUrl}'`);
                throw new Error(e);
            })
        ;

        bootstrapTabData.content = $('<div></div>')
            .attr(bootstrapTabData.containerAttr)
            .html(content)
        ;
    }

    /**
     * Vérifie si l'architecture est correcte
     * @private
     */
    _checkContainer() {
        this.$nav = $(this.element).find('.nav.nav-tabs').first();
        if (this.$nav.length === 0) {
            throw new Error(`[BootstrapTabs] Container must have div child with classes : 'nav nav-tabs' !`);
        }

        this.$tabContent = $(this.element).find('.tab-content').first();
        if (this.$tabContent.length === 0) {
            throw new Error(`[BootstrapTabs] Container must have div child with class : 'tab-content' !`);
        }
    }

    /**
     * Ajout le bouton "fermer" sur un onglet
     * @param {BootstrapTabData} tabData
     * @param {HTMLLinkElement} $a
     * @private
     */
    _plugRemovable(tabData, $a) {
        const self = this;

        const $span_remove = $('<span></span>')
            .addClass('close ms-2')
            .html('×')
        ;

        $span_remove.on('click', function () {
            if (tabData.warnOnRemove) {
                new TablerModal()
                    .confirm("Vous allez perdre les données de l'onglet.<br/><br/>Voulez-vous quand même continuer ?")
                    .then((confirm) => {
                        if (confirm) {
                            self.removeTab(tabData.id);
                        }
                    })
                ;
            } else {
                self.removeTab(tabData.id);
            }
        });

        $a.append($span_remove);
    }
}

/**
 * Structure permettant de renseigner des données d'un onglet
 */
class BootstrapTabData {
    constructor(id, title) {
        this.id = id;
        this.title = title;

        this.containerAttr = {};

        this.navClass = 'float-end';

        this.linkClass = 'bg-warning';
        this.contentClass = '';

        this.content = null;
        this.contentUrl = null;

        this.removable = true;
        this.warnOnRemove = true;
    }
}
