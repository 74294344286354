import { ContextMenuItem } from './ContextMenuItem';
export class ContextMenuItemLink extends ContextMenuItem {
    constructor(label, href = '#', action = undefined) {
        super();
        this.label = label;
        this.href = href;
        this.action = action;
    }
    render(dropdown, focusout) {
        const a = document.createElement('a');
        this._initMenuItem(a);
        if (this.faClass) {
            const i = document.createElement('i');
            i.classList.add(...this.faClass.split(' '), 'me-2');
            a.appendChild(i);
            const span = document.createElement('span');
            span.innerText = this.label;
            a.appendChild(span);
        }
        else {
            a.innerText = this.label;
        }
        this._setItemContent(a, this.label, false);
        a.href = this.href;
        a.onclick = () => {
            (dropdown.parentNode.parentNode).remove();
            focusout.remove();
            if (this.action && this.disabled === false)
                this.action();
        };
        dropdown.appendChild(a);
    }
}
