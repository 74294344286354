import { Controller } from '@hotwired/stimulus';
export default class CardFilter extends Controller {
    constructor() {
        super(...arguments);
        this.timeout = null;
    }
    get form() {
        return this.element.querySelector('form');
    }
    connect() {
        super.connect();
        if (this.autoSearchValue) {
            this.element.querySelectorAll('input:not([type="checkbox"])').forEach((input) => {
                input.addEventListener('keyup', (e) => this.onInputChanged(e));
            });
            this.element.querySelectorAll('select, input[type="checkbox"]').forEach((input) => {
                input.addEventListener('change', (e) => this.onInputChanged(e));
            });
        }
    }
    onInputChanged(event) {
        const input = event.target;
        if (input instanceof HTMLInputElement) {
            const ignoreTimer = ['button', 'checkbox', 'color', 'file', 'image', 'radio'];
            if (ignoreTimer.includes(input.type)) {
                // Update directement
                this.updateListFromFilter();
            }
            else if (input.value.length >= this.minInputLengthValue) {
                // Update avec un Timer
                this.startTimer();
            }
        }
        else if (input instanceof HTMLSelectElement) {
            // Update directement
            this.updateListFromFilter();
        }
        else {
            // Cas non pris en charge → update direct pour être safe
            this.updateListFromFilter();
        }
    }
    updateListFromFilter() {
        this.dispatch('autoSubmit', { detail: { form: this.form } });
    }
    startTimer() {
        this.stopTimer();
        const card = this.form.closest('.card');
        let cardProgressBar = card.querySelector('.progress.card-progress');
        if (cardProgressBar !== null) {
            cardProgressBar.remove();
        }
        cardProgressBar = document.createElement('div');
        cardProgressBar.classList.add('progress', 'progress-sm', 'card-progress');
        const progressBar = document.createElement('div');
        progressBar.classList.add('progress-bar');
        progressBar.style.transition = `none`;
        progressBar.style.width = '0%';
        cardProgressBar.appendChild(progressBar);
        card.appendChild(cardProgressBar);
        progressBar.style.transition = `none`;
        progressBar.style.width = '0%';
        progressBar.style.transition = `linear ${(this.timeoutValue / 1000).toFixed(2)}s`;
        progressBar.setAttribute('role', 'progressbar');
        setTimeout(() => {
            progressBar.style.width = '100%';
        }, 100);
        this.timeout = window.setTimeout(() => {
            this.updateListFromFilter();
        }, this.timeoutValue);
    }
    stopTimer() {
        if (this.timeout) {
            window.clearInterval(this.timeout);
            this.timeout = undefined;
        }
    }
}
CardFilter.values = {
    autoSearch: Boolean,
    timeout: { type: Number, default: 3000 },
    minInputLength: { type: Number, default: 3 }
};
