import { Controller } from '@hotwired/stimulus';
export default class default_1 extends Controller {
}
default_1.targets = [
    'raisonSociale',
    'autoUpdate',
    'cheminBaseArticle',
    'urlHFSQL',
    'portHFSQL',
    'utilisateurHFSQL',
    'baseHFSQL',
];
