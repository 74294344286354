import { Controller } from '@hotwired/stimulus';
import Cookies from 'js-cookie';
import _ from 'lodash';
const CST_locale = {
    all: 'Tous (page courante)',
    allFromSearch: 'Tous (depuis recherche actuelle)',
    none: 'Aucun',
    countElements: '%count% élément(s)'
};
export var SelectionMode;
(function (SelectionMode) {
    SelectionMode["AllFilter"] = "allFilter";
    SelectionMode["CurrentPage"] = "currentPage";
})(SelectionMode || (SelectionMode = {}));
/**
 * Module permettant de rajouter et gérer la sélection multiple
 * de ligne dans un tableau HTML.
 *
 * - Si pas de thead, pas de case "tout cocher"
 * - Chaque ligne <tr> doit avoir un attribut `data-id`
 */
export default class TableSelect extends Controller {
    constructor() {
        super(...arguments);
        // Checkbox "All"
        this.checkboxAll = undefined;
        // Checkboxes de chaque ligne du tableau
        this.checkboxes = new Set();
        this.selectionMode = SelectionMode.CurrentPage;
    }
    get locale() {
        return Object.assign(Object.assign({}, CST_locale), this.localeValue);
    }
    /**
     * Retourne nombre d'éléments considérés comme cochés
     */
    get countChecked() {
        if (this.selectionMode === SelectionMode.AllFilter) {
            return this.maxItemsValue;
        }
        let countChecked = 0;
        this.checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                countChecked++;
            }
        });
        return countChecked;
    }
    /**
     * Retourne les valeurs des checkbox cochées sur la page en cours
     */
    get checkedValues() {
        const values = [];
        this.checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                values.push(checkbox.value);
            }
        });
        return values;
    }
    get selectedData() {
        if (this.selectionMode === SelectionMode.AllFilter && this.hasFormFilterTarget) {
            // En mode TOUS les éléments résultant du filtre
            const formData = new FormData(this.formFilterTarget);
            // Conversion du FormData en Objet pour le JSON
            const object = {};
            formData.forEach((value, key) => {
                if (!Reflect.has(object, key)) {
                    object[key] = value;
                    return;
                }
                if (!Array.isArray(object[key])) {
                    object[key] = [object[key]];
                }
                object[key].push(value);
            });
            return JSON.stringify(object);
        }
        else if (this.selectionMode === SelectionMode.CurrentPage) {
            // Tableau simple des ID
            return JSON.stringify(this.checkedValues);
        }
        return null;
    }
    get dataType() {
        switch (this.selectionMode) {
            case SelectionMode.AllFilter:
                return 'formData';
            case SelectionMode.CurrentPage:
                return 'array';
            default:
                throw new Error('Case not expected!');
        }
    }
    connect() {
        super.connect();
        if (this.enabledValue === false) {
            return;
        }
        if (this.element instanceof HTMLTableElement) {
            throw new Error('Error: Stimulus `table-select` SHOULD NOT be plugged to a <table> element');
        }
        else if (!(this.element instanceof HTMLDivElement)) {
            throw new Error('Error: Stimulus `table-select` SHOULD be plugged to a <div> element');
        }
        else if (this.hasActions() === false) {
            const table = this.element.querySelector('table');
            console.warn('Warning `table-select`: no action defined for the table', table);
            return;
        }
        this.initHTML();
        this.updateCheckboxAllState();
        this.updateTools();
    }
    /**
     * Construit HTML pour rendre les checkboxes
     */
    initHTML() {
        const table = this.element.querySelector('table');
        const theadTr = table.querySelector('thead tr');
        if (theadTr) {
            // Ajout nouvelle colonne pour "checkbox all"
            const th = document.createElement('th');
            th.classList.add('w-1');
            th.scope = 'col';
            th.style.textTransform = 'inherit';
            // Création "checkbox all"
            this.checkboxAll = createCheckbox(this.nameValue + '_table_select[all]');
            this.checkboxAll.onchange = (e) => this.onSelectAllChanged(e);
            if (this.dropdownValue) {
                // Création du DropDown
                const btnGroup = document.createElement('div');
                btnGroup.classList.add('btn-group');
                const btnCheckbox = document.createElement('button');
                btnCheckbox.classList.add('btn', 'btn-invisible', 'p-0');
                btnCheckbox.appendChild(this.checkboxAll);
                btnGroup.appendChild(btnCheckbox);
                const btnDropdown = document.createElement('button');
                btnDropdown.classList.add('btn', 'btn-invisible', 'dropdown-toggle', 'dropdown-toggle-split', 'pe-0');
                btnDropdown.setAttribute('data-bs-toggle', 'dropdown');
                btnGroup.appendChild(btnDropdown);
                let dropdownItems = [
                    { text: this.locale.all, onclick: () => this.checkAll() },
                    { text: this.locale.none, onclick: () => this.checkNone() }
                ];
                if (this.hasFormFilterTarget) {
                    dropdownItems = [
                        ...dropdownItems,
                        ...[
                            { divider: true },
                            { text: this.locale.allFromSearch, onclick: () => this.checkForAll() }
                        ]
                    ];
                }
                const dropdownMenu = createDropDownMenu(dropdownItems);
                btnGroup.appendChild(dropdownMenu);
                th.appendChild(btnGroup);
            }
            else {
                // Checkbox directe
                th.appendChild(this.checkboxAll);
            }
            theadTr.insertBefore(th, theadTr.querySelector('th:nth-child(' + (this.indexColumnValue + 1) + ')'));
        }
        // Création des checkbox pour chaque ligne
        table.querySelectorAll('tbody tr').forEach((tr) => {
            var _a;
            const id = tr.dataset.id;
            if (id === undefined) {
                // impossible de trouver l'id de la ligne
                console.error(tr);
                throw new Error('Error: Stimulus `table-select` <tr> has no `data-id` field!');
            }
            // Création de la cellule pour la case à cocher de l'item
            const td = document.createElement('td');
            td.setAttribute('data-no-href', 'data-no-href');
            // Création de la checkbox
            const checkbox = createCheckbox(this.nameValue + '_table_select[item][]', id);
            checkbox.onchange = (e) => this.onItemSelectChanged(e);
            td.appendChild(checkbox);
            this.checkboxes.add(checkbox);
            if (this.memorizeValue) {
                // Récupération de l'état de la checkbox depuis les cookies
                const cookieValue = (_a = Cookies.get(checkbox.name)) !== null && _a !== void 0 ? _a : 'false';
                checkbox.checked = Boolean(JSON.parse(cookieValue));
            }
            tr.insertBefore(td, tr.querySelector('td:nth-child(' + (this.indexColumnValue + 1) + ')'));
        });
    }
    hasActions() {
        return this.actionContainerTarget.childNodes.length > 1;
    }
    /**
     * Check pour tous + flag global
     */
    checkForAll() {
        this.selectionMode = SelectionMode.AllFilter;
        this.checkAll(true);
    }
    /**
     * Check pour tous les elements de la page
     */
    checkAll(manual = false) {
        if (manual === false) {
            this.selectionMode = SelectionMode.CurrentPage;
        }
        this.checkboxAll.indeterminate = false;
        this.checkboxAll.checked = true;
        this.updateCheckboxesState();
    }
    /**
     * De-Check tous les elements
     */
    checkNone() {
        this.selectionMode = SelectionMode.CurrentPage;
        this.checkboxAll.indeterminate = false;
        this.checkboxAll.checked = false;
        this.updateCheckboxesState();
    }
    onSelectAllChanged(e) {
        if (this.checkboxAll.checked) {
            this.checkAll();
        }
        else {
            this.checkNone();
        }
    }
    onItemSelectChanged(e) {
        this.updateCheckboxAllState();
        this.selectionMode = SelectionMode.CurrentPage;
        this.updateTools();
        this.memorizeCheckboxesStates();
        this.dispatchChecked();
    }
    /**
     * Met à jour l'état des checkboxes en fonction de la checkbox "All"
     */
    updateCheckboxesState() {
        if (this.checkboxAll === undefined) {
            return;
        }
        this.checkboxes.forEach((checkbox) => {
            checkbox.checked = this.checkboxAll.checked;
        });
        this.updateTools();
        this.memorizeCheckboxesStates();
        this.dispatchChecked();
    }
    /**
     * Met à jour l'état de la checkbox "All" en fonction des checkboxes
     */
    updateCheckboxAllState() {
        if (this.checkboxAll === undefined) {
            return;
        }
        const countChecked = this.countChecked;
        this.checkboxAll.checked = countChecked >= this.checkboxes.size;
        this.checkboxAll.indeterminate = _.inRange(countChecked, 1, this.checkboxes.size);
    }
    /**
     * Mémorise l'état actuel des checkboxes en Cookie navigateur
     */
    memorizeCheckboxesStates() {
        if (this.memorizeValue === false) {
            return;
        }
        if (this.checkboxAll) {
            setCookie(this.checkboxAll.name, this.checkboxAll.checked);
        }
        this.checkboxes.forEach((checkbox) => {
            setCookie(checkbox.name, checkbox.checked);
        });
        function setCookie(name, checked) {
            Cookies.set(name, String(checked), {
                expires: 1,
                path: window.location.pathname,
                sameSite: 'strict'
            });
        }
    }
    /**
     * Met à jour les tools en fonction de la selection
     */
    updateTools() {
        // Met à jour le nombre d'éléments cochés
        const countChecked = this.countChecked;
        this.countItemsTarget.innerText = this.locale.countElements.replace('%count%', countChecked.toString());
        // Affiche ou cache la toolbox
        if (countChecked >= this.minItemsSelectedToolsValue) {
            this.toolsTarget.style.display = 'block';
            this.toolsTarget.style.opacity = '100';
            setTimeout(() => this.toolsTarget.style.transform = 'translate(0%, 0%)', 25);
        }
        else {
            this.toolsTarget.style.transform = 'translate(0%, 200%)';
            this.toolsTarget.style.opacity = '0';
            setTimeout(() => this.toolsTarget.style.display = 'none', 250);
        }
    }
    dispatchChecked() {
        this.dispatch('checked', {
            detail: {
                selectionMode: this.selectionMode,
                count: this.countChecked,
                selectedData: this.selectedData,
                dataType: this.dataType
            }
        });
    }
}
TableSelect.values = {
    maxItems: Number,
    name: { type: String, default: 'unnamed' },
    enabled: { type: Boolean, default: true },
    indexColumn: { type: Number, default: 0 },
    minItemsSelectedTools: { type: Number, default: 1 },
    dropdown: { type: Boolean, default: false },
    memorize: { type: Boolean, default: false },
    locale: { type: Object, default: CST_locale }
};
TableSelect.targets = [
    'formFilter',
    'tools',
    'countItems',
    'actionContainer',
];
/**
 * Créer un input checkbox
 */
function createCheckbox(name, value = '') {
    const input = document.createElement('input');
    input.type = 'checkbox';
    input.classList.add('form-check-input', 'm-0', 'align-middle');
    input.name = name;
    input.value = value;
    return input;
}
/**
 * Créer un dropdown Boostrap
 */
function createDropDownMenu(items) {
    const dropdownMenu = document.createElement('ul');
    dropdownMenu.classList.add('dropdown-menu');
    items.forEach((item) => {
        const li = document.createElement('li');
        if (item.divider) {
            const div = document.createElement('div');
            div.classList.add('dropdown-divider');
            li.appendChild(div);
        }
        else {
            const a = document.createElement('a');
            a.classList.add('dropdown-item');
            a.href = '#';
            a.text = item.text;
            if (item.onclick) {
                a.onclick = item.onclick;
            }
            li.appendChild(a);
        }
        dropdownMenu.appendChild(li);
    });
    return dropdownMenu;
}
