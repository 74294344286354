export function stringToHTML(string) {
    const template = document.createElement('template');
    template.innerHTML = string.trim();
    return template.content.firstChild;
}
/**
 * @deprecated Use stringToBool() instead!
 */
export function strToBool(s) {
    return stringToBool(s);
}
/**
 * Converti un "string boolean" en vrai "Boolean".
 */
export function stringToBool(s) {
    if (s === null || s === undefined) {
        return false;
    }
    if (typeof s === 'boolean') {
        return s;
    }
    return !(/^(false|0)$/i).test(s);
}
export function slugify(str) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');
    return str.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}
/**
 * Retourne le nom du fichier depuis un "Content-Disposition".
 */
export function getFileNameFromContentDisposition(contentDisposition) {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
    let fileName = null;
    if (utf8FilenameRegex.test(contentDisposition)) {
        fileName = decodeURIComponent(utf8FilenameRegex.exec(contentDisposition)[1]);
    }
    else {
        // prevent ReDos attacks by anchoring the ascii regex to string start and
        // slicing off everything before 'filename='
        const filenameStart = contentDisposition.toLowerCase().indexOf('filename=');
        if (filenameStart >= 0) {
            const partialDisposition = contentDisposition.slice(filenameStart);
            const matches = asciiFilenameRegex.exec(partialDisposition);
            if (matches != null && matches[2]) {
                fileName = matches[2];
            }
        }
    }
    return fileName;
}
