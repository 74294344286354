import {Controller} from '@hotwired/stimulus';
import {TablerToast, TablerToastContainer} from "../js/modules/TablerToast";
import TablerModal from "../js/modules/TablerModal";
import {init_sortable} from "../js/import_modules";

/**
 * Prérequis :
 * - Ce module doit être appelé en tant que premier PARENT direct des items.
 *   Si un élément se trouve entre deux, sortableJs ne fonctionnera pas.
 *
 * Prérequis pour un item :
 * - data-id : id de l'élément (ou "data-uuid")
 * - data-uuid : uuid de l'élément (ou "data-id")
 * Optionnel pour un item :
 * - data-position : position de l'élément dans la liste
 *
 * Résultat lors d'un tri :
 * - Appel de l'url `ajaxPostPositionUrl`
 * - En body `identifiersInOrder` qui est un string JSON
 * - Contenant les identifiants des éléments dans l'ordre soit "data-uuid" ou "data-id"
 */
export default class SortableJs extends Controller {

    // Conteneur des toasts
    _toastContainer = new TablerToastContainer();

    static values = {
        ajaxPostPositionUrl: String, // URL du controller qui attend un AJAX pour mettre à jour les positions
        draggable: {type: String, default: '.sortable-js-item'} // Classe des éléments qui peuvent être déplacés
    }

    get ajaxPostPositionUrl() {
        return this.ajaxPostPositionUrlValue ?? undefined
    }

    get draggable() {
        return this.draggableValue
    }

    connect() {
        super.connect();

        this._abortIfMissingRequiredConfig();

        if (this.element.classList.contains('sortable-js') === false) {
            // Si l'élément n'est pas déjà en mode sortableJS, on le met en mode sortableJS
            this.element.classList.add('sortable-js');
            init_sortable(this.element.parentElement);
        }

        // À la fin d'un tri sortableJS
        this.element.addEventListener('sortable:onEnd', (customEvent) => {
            // Récupération de l'event sortable dans un CustomEvent()
            // https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
            const sortableEvent = customEvent.detail.sortableEvent;
            // Appel de la fonction qui gère la fin d'un tri
            this.onSortableEnd(sortableEvent);
        });
    }

    /**
     * Vérifie les prérequis du module
     * @private
     */
    _abortIfMissingRequiredConfig() {

        let errors = new Set();

        if (errors.size > 0) {
            console.error(this.element);
            throw new Error(Array.from(errors).join("\n"));
        }
    }

    /**
     * Fonction qui gère la fin d'un tri sortableJS
     */
    onSortableEnd(event) {
        // Element HTML (liste) qui a subi le tri
        const listHtml = event.to;

        // Tableau de données des id de corps d'état
        // La position des éléments dans ce tableau correspond à sa position finale
        const identifiersInOrder = new Set();

        // Pour tous les items de la liste
        const itemsHtml = listHtml.querySelectorAll(this.draggable);
        itemsHtml.forEach((itemHtml, index) => {
            const position = index + 1;

            let identifier;
            if (itemHtml.dataset.uuid) {
                // Récupération de UUID (data-uuid)
                identifier = itemHtml.dataset.uuid;
            } else if (itemHtml.dataset.id) {
                // Récupération de l'id (data-id)
                identifier = itemHtml.dataset.id;
            } else {
                throw new Error("SortableJs: Impossible de récupérer l'identifiant de l'élément. 'data-uuid' ou 'data-id' n'est pas défini");
            }

            // Recherche de l'élément HTML qui affiche la position à l'utilisateur
            const positionEl = itemHtml.querySelector('*[data-position]');
            if (positionEl) {
                // Update de data-position
                positionEl.dataset.position = position.toString();
                if (positionEl instanceof HTMLInputElement) {
                    positionEl.value = position.toString()
                } else {
                    // Update de l'affichage de la position
                    positionEl.innerText = position;
                }
            }

            // Ajout de l'id du corps d'état dans le tableau
            identifiersInOrder.add(identifier);
        });

        if (!this.ajaxPostPositionUrl){
            // Pas d'url pour l'AJAX
            // Ne rien faire de plus
            return
        }

        // Création du "body" de la requête AJAX
        const body = new FormData();
        // Ajout la liste des id de corps d'état dans le body en JSON
        body.append('identifiersInOrder', JSON.stringify(Array.from(identifiersInOrder)));

        // Appel AJAX sur l'url de mise à jour des positions
        // https://stimulus.hotwired.dev/reference/values
        // Méthode POST
        fetch(this.ajaxPostPositionUrl, {
            method: 'POST',
            body: body,
        }).then((response) => {
            if (response.ok) {
                const toast = new TablerToast('Nouvel ordonnancement enregistré.', 'Donnée enregistrée');
                this._toastContainer.pushToast(toast);
            } else {
                response.json().then((json) => {
                    handleError(json);
                });
            }
        }).catch((e) => {
            // Une erreur est survenue suite à AJAX ...
            handleError(e);
        })
        ;
    }
}

function handleError(e = null) {
    console.error(e);
    (new TablerModal()).error("Erreur lors de la sauvegarde du changement de l'ordre.");
}
