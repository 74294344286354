import { Controller } from '@hotwired/stimulus';
import TomSelect from "tom-select";
import { parsePhoneNumber } from "libphonenumber-js";
export default class TelMondialInput extends Controller {
    connect() {
        super.connect();
        this.element.hidden = true;
        const row = document.createElement('div');
        row.classList.add('row', 'g-1');
        const col_select = document.createElement('div');
        col_select.classList.add('col-auto');
        const select = document.createElement('select');
        for (const [alpha2, name] of Object.entries(this.countriesValue)) {
            const option = document.createElement('option');
            option.value = alpha2.toString();
            option.text = name;
            select.appendChild(option);
        }
        col_select.appendChild(select);
        row.appendChild(col_select);
        const col_input = document.createElement('div');
        col_input.classList.add('col');
        this.input = document.createElement('input');
        this.input.classList.add('form-control');
        this.input.type = "tel";
        this.input.oninput = (e) => this.definePhoneNumber();
        this.input.required = this.element.required;
        this.element.required = false;
        col_input.appendChild(this.input);
        row.appendChild(col_input);
        this.element.after(row);
        this.tomselect = new TomSelect(select, {
            items: [this.defaultCountryValue],
            plugins: ['dropdown_input'],
            render: {
                option: function (item, escape) {
                    return `
                    <div data-value="${item.value}" class="option" role="option">
                        <span class="dropdown-item-indicator">
                            <span class="flag flag-xs flag-country-${item.value.toLowerCase()} pe-1"></span>
                        </span>
                        ${item.text}
                    </div>
                    `;
                },
                item: function (item, escape) {
                    return `<span class="flag flag-xs flag-country-${item.value.toLowerCase()}"></span>`;
                }
            },
        });
        if (!!this.element.value) {
            this.input.value = this.element.value;
            this.definePhoneNumber();
        }
        this.tomselect.on('change', (value) => this.definePhoneNumber());
        this.tomselect.wrapper.classList.add('form-select');
        this.tomselect.wrapper.querySelector('.ts-dropdown').style.width = "250px";
    }
    definePhoneNumber() {
        const removeInvalidState = () => {
            this.input.classList.remove('is-invalid');
            this.input.setCustomValidity('');
            if (errorDiv) {
                errorDiv.remove();
            }
        };
        let errorDiv = this.element.parentElement.querySelector('.invalid-feedback');
        try {
            const phoneNumber = parsePhoneNumber(this.input.value, this.tomselect.getValue());
            if (phoneNumber) {
                if (phoneNumber.isValid() && phoneNumber.country in this.countriesValue) {
                    this.tomselect.setValue(phoneNumber.country, true);
                    removeInvalidState();
                }
                else {
                    this.input.classList.add('is-invalid');
                    this.input.required = true;
                    this.input.setCustomValidity(this.invalidMessageValue);
                    if (errorDiv === null) {
                        errorDiv = document.createElement('div');
                        errorDiv.classList.add('invalid-feedback');
                        errorDiv.innerText = this.invalidMessageValue;
                        this.input.after(errorDiv);
                    }
                }
                this.element.value = phoneNumber.number;
            }
        }
        catch (e) {
            if (this.input.value.length === 0) {
                removeInvalidState();
            }
            else {
                this.input.classList.toggle('is-invalid', true);
            }
            return;
        }
    }
}
TelMondialInput.values = {
    defaultCountry: String,
    countries: Object,
    invalidMessage: { type: String, default: 'Please provide a valid phone number.' },
};
