import { tomSelectRequired, waitForTomSelect } from './tomselect';
/**
 * Rend un input visible ou non + gestion "required"
 */
export function inputVisible(element, visible, required = visible, viaClass = true) {
    const parent = element.parentElement;
    if (viaClass) {
        parent.classList[visible ? 'remove' : 'add']('d-none');
    }
    else {
        parent.hidden = visible;
    }
    if (element instanceof HTMLInputElement || element instanceof HTMLSelectElement) {
        if (/tom-select/.test(element.className)) {
            waitForTomSelect(element).then((tomSelect) => {
                tomSelectRequired(tomSelect, required);
            });
        }
        else {
            fieldRequired(element, required);
        }
    }
    else if (element instanceof HTMLDivElement) {
        element.querySelectorAll('input[type="radio"],input[type="checkbox"]').forEach((input) => {
            fieldRequired(input, required);
        });
        element.hidden = visible === false;
    }
    else {
        throw new Error(`element (${typeof element}) type is not expected!`);
    }
}
/**
 * Rend un champ HTML en tant que "requis".
 * Gère aussi les labels pour les astérisques.
 */
export function fieldRequired(field, required) {
    // Set de l'attribut
    field.required = required;
    // Ajout de la class
    field.classList.toggle('required', required);
    field.labels.forEach(label => {
        // Ajout de la class pour avoir "*" en suffix
        label.classList.toggle('required', required);
    });
}
