import { Controller } from '@hotwired/stimulus';
export default class default_1 extends Controller {
    connect() {
        super.connect();
        this.onProjetChange();
    }
    onProjetChange() {
        [this.projetSourceTarget, this.projetCibleTarget].forEach((projet_form) => {
            const selected_projet = projet_form[projet_form.selectedIndex];
            const projet_help = projet_form.form.querySelector(`#${projet_form.id}_help`);
            projet_help.innerText = "Version en cours : " + selected_projet.dataset.version;
        });
    }
}
default_1.targets = [
    "projetSource",
    "projetCible",
];
