import {Controller} from '@hotwired/stimulus';

import * as FilePond from "filepond";
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import fr_fr from 'filepond/locale/fr-fr';

fr_fr.labelIdle = 'Faites glisser vos fichiers ici ou <span class = "filepond--label-action"> Parcourir <span>';

export default class FilepondInput extends Controller {

    EVENTS = [
        'init',
        'warning',
        'error',
        'initfile',
        'addfilestart',
        'addfileprogress',
        'addfile',
        'processfilestart',
        'processfileprogress',
        'processfileabort',
        'processfilerevert',
        'processfile',
        'processfiles',
        'removefile',
        'preparefile',
        'updatefiles',
        'activatefile',
        'reorderfiles',
    ]

    static values = {
        name: String,
        className: String,
        required: Boolean,
        disabled: Boolean,
        captureMethod: String,
        // files: todo
        allowDrop: Boolean,
        allowBrowse: Boolean,
        allowPaste: Boolean,
        allowMultiple: Boolean,
        allowReplace: Boolean,
        allowRevert: Boolean,
        allowRemove: Boolean,
        allowProcess: Boolean,
        allowReorder: Boolean,
        storeAsFile: Boolean,
        forceRevert: Boolean,
        maxFiles: String,
        maxParallelUploads: Number,
        checkValidity: Boolean,
        itemInsertLocation: String,
        itemInsertInterval: Number,
        // Drag n' Drop
        dropOnPage: Boolean,
        dropOnElement: Boolean,
        dropValidation: Boolean,
        ignoredFiles: Array,
        // Server
        server: String,
        url: String,
        process: String,
        revert: String,
        restore: String,
        load: String,
        fetch: String,
        instantUpload: Boolean,
        chunkUploads: Boolean,
        chunkForce: Boolean,
        chunkSize: Number,
        // ** Plugin : File size validation
        allowFileSizeValidation: Boolean,
        minFileSize: String,
        maxFileSize: String,
        maxTotalFileSize: String,
        // ** Plugin : File type validation
        allowFileTypeValidation: Boolean,
        acceptedFileTypes: Array,
        fileValidateTypeLabelExpectedTypesMap: Object,
        // ** Plugin : File image preview
        allowImagePreview: Boolean,
        imagePreviewMinHeight: Number,
        imagePreviewMaxHeight: Number,
        imagePreviewHeight: String,
        imagePreviewMaxFileSize: String,
        imagePreviewTransparencyIndicator: String,
        imagePreviewMaxInstantPreviewFileSize: Number,
        imagePreviewMarkupShow: Boolean,
        // ** Plugin : File image resize
        allowImageResize: Boolean,
        imageResizeTargetWidth: String,
        imageResizeTargetHeight: String,
        imageResizeMode: String,
        imageResizeUpscale: Boolean,
        // ** Plugin : File image crop
        allowImageCrop: Boolean,
        imageCropAspectRatio: String,
    }

    /** @type FilePond */
    pond = undefined;

    get name() {
        return this.nameValue
    }

    get className() {
        return this.classNameValue
    }

    get required() {
        return this.requiredValue
    }

    get disabled() {
        return this.disabledValue
    }

    get allowDrop() {
        return this.allowDropValue
    }

    get allowBrowse() {
        return this.allowBrowseValue
    }

    get allowPaste() {
        return this.allowPasteValue
    }

    get allowMultiple() {
        return this.allowMultipleValue
    }

    get allowReplace() {
        return this.allowReplaceValue
    }

    get allowRevert() {
        return this.allowRevertValue
    }

    get allowRemove() {
        return this.allowRemoveValue
    }

    get allowProcess() {
        return this.allowProcessValue
    }

    get allowReorder() {
        return this.allowReorderValue
    }

    get storeAsFile() {
        return this.storeAsFileValue
    }

    get forceRevert() {
        return this.forceRevertValue
    }

    get maxFiles() {
        return !!this.maxFilesValue ? Number(this.maxFilesValue) : null
    }

    get maxParallelUploads() {
        return this.maxParallelUploadsValue
    }

    get checkValidity() {
        return this.checkValidityValue
    }

    // ** Drag n' Drop **

    get dropOnPage() {
        return this.dropOnPageValue
    }

    get dropOnElement() {
        return this.dropOnElementValue
    }

    get dropValidation() {
        return this.dropValidationValue
    }

    get ignoredFiles() {
        return this.ignoredFilesValue
    }

    // ** Server **

    get server() {
        return this.serverValue
    }

    get url() {
        return this.urlValue
    }

    get process() {
        return this.processValue
    }

    get revert() {
        return this.revertValue
    }

    get restore() {
        return this.restoreValue
    }

    get load() {
        return this.loadValue
    }

    get fetch() {
        return this.fetchValue
    }

    get instantUpload() {
        return this.instantUploadValue
    }

    get chunkUploads() {
        return this.chunkUploadsValue
    }

    get chunkForce() {
        return this.chunkForceValue
    }

    get chunkSize() {
        return this.chunkSizeValue
    }

    // ** Plugin : File size validation **

    get allowFileSizeValidation() {
        return this.allowFileSizeValidationValue
    }

    get minFileSize() {
        return !!this.minFileSizeValue ? Number(this.minFileSizeValue) : null
    }

    get maxFileSize() {
        return !!this.maxFileSizeValue ? Number(this.maxFileSizeValue) : null
    }

    get maxTotalFileSize() {
        return !!this.maxTotalFileSizeValue ? Number(this.maxTotalFileSizeValue) : null
    }

    // ** Plugin : File type validation **

    get allowFileTypeValidation() {
        return this.allowFileTypeValidationValue
    }

    get acceptedFileTypes() {
        return this.acceptedFileTypesValue
    }

    get fileValidateTypeLabelExpectedTypesMap() {
        return this.fileValidateTypeLabelExpectedTypesMapValue
    }

    // ** Plugin : File image preview **

    get allowImagePreview() {
        return this.allowImagePreviewValue
    }

    get imagePreviewMinHeight() {
        return this.imagePreviewMinHeightValue
    }

    get imagePreviewMaxHeight() {
        return this.imagePreviewMaxHeightValue
    }

    get imagePreviewHeight() {
        return !!this.imagePreviewHeightValue ? Number(this.imagePreviewHeightValue) : null
    }

    get imagePreviewMaxFileSize() {
        return !!this.imagePreviewMaxFileSizeValue ? Number(this.imagePreviewMaxFileSizeValue) + 'B' : null
    }

    get imagePreviewTransparencyIndicator() {
        return !!this.imagePreviewTransparencyIndicatorValue ? this.imagePreviewTransparencyIndicatorValue : null
    }

    get imagePreviewMaxInstantPreviewFileSize() {
        return this.imagePreviewMaxInstantPreviewFileSizeValue
    }

    get imagePreviewMarkupShow() {
        return this.imagePreviewMarkupShowValue
    }

    // ** Plugin : File image resize **

    get allowImageResize() {
        return this.allowImageResizeValue
    }

    get imageResizeTargetWidth() {
        return !!this.imageResizeTargetWidthValue ? Number(this.imageResizeTargetWidthValue) : null
    }

    get imageResizeTargetHeight() {
        return !!this.imageResizeTargetHeightValue ? Number(this.imageResizeTargetHeightValue) : null
    }

    get imageResizeMode() {
        return this.imageResizeModeValue
    }

    get imageResizeUpscale() {
        return this.imageResizeUpscaleValue
    }

    // ** Plugin : File image crop **

    get allowImageCrop() {
        return this.allowImageCropValue
    }

    get imageCropAspectRatio() {
        return !!this.imageCropAspectRatioValue ? this.imageCropAspectRatioValue : null
    }

    connect() {
        super.connect();

        const input = this.element.querySelector('input')
        input.hidden = true
        input.classList.remove('form-control')

        FilePond.registerPlugin(
            FilePondPluginFileValidateType,
            FilePondPluginFileValidateSize,
            FilePondPluginImageExifOrientation,
            FilePondPluginImagePreview,
            FilePondPluginImageEdit
        );

        let needsTransformPlugin = false
        if (this.allowImageResize) {
            FilePond.registerPlugin(FilePondPluginImageResize)
            needsTransformPlugin = true
        }

        if (this.allowImageCrop) {
            FilePond.registerPlugin(FilePondPluginImageCrop)
            needsTransformPlugin = true
        }

        if (needsTransformPlugin) {
            FilePond.registerPlugin(FilePondPluginImageTransform)
        }

        FilePond.setOptions(fr_fr);
        this.pond = FilePond.create(input, this._getOptions());

        this.pond.on('error', (e) => {
            console.error(e)
        });

        // Branchement des events FilePond en events stimulus
        this.EVENTS.forEach((eventName) => {
            this.pond.on(eventName, (e) => {
                this.dispatch(eventName, {detail: {event: e, pond: this.pond}})
            });
        })
    }

    _getOptions() {
        const serverOptions = () => {
            if (this.server) return this.server
            let server = {
                process: !!this.process ? this.process : null,
                revert: !!this.revert ? this.revert : null,
                restore: !!this.restore ? this.restore : null,
                load: !!this.load ? this.load : null,
                fetch: !!this.fetch ? this.fetch : null,
            }

            if (!!this.url) server.url = this.url

            return server
        }


        return {
            name: this.name,
            className: this.className,
            required: this.required,
            disabled: this.disabled,
            captureMethod: this.captureMethod,
            // files: todo
            allowDrop: this.allowDrop,
            allowBrowse: this.allowBrowse,
            allowPaste: this.allowPaste,
            allowMultiple: this.allowMultiple,
            allowReplace: this.allowReplace,
            allowRevert: this.allowRevert,
            allowRemove: this.allowRemove,
            allowProcess: this.allowProcess,
            allowReorder: this.allowReorder,
            storeAsFile: this.storeAsFile,
            forceRevert: this.forceRevert,
            maxFiles: this.maxFiles,
            maxParallelUploads: this.maxParallelUploads,
            checkValidity: this.checkValidity,
            itemInsertLocation: this.itemInsertLocation,
            itemInsertInterval: this.itemInsertInterval,
            // Drag n' Drop
            dropOnPage: this.dropOnPage,
            dropOnElement: this.dropOnElement,
            dropValidation: this.dropValidation,
            ignoredFiles: this.ignoredFiles,
            // Server
            server: serverOptions(),
            instantUpload: this.instantUpload,
            chunkUploads: this.chunkUploads,
            chunkForce: this.chunkForce,
            chunkSize: this.chunkSize,
            // ** Plugin : File size validation
            allowFileSizeValidation: this.allowFileSizeValidation,
            minFileSize: this.minFileSize ? this.minFileSize + 'B' : null,
            maxFileSize: this.maxFileSize ? this.maxFileSize + 'B' : null,
            maxTotalFileSize: this.maxTotalFileSize ?? null,
            // ** Plugin : File type validation
            allowFileTypeValidation: this.allowFileTypeValidation,
            acceptedFileTypes: this.acceptedFileTypes,
            fileValidateTypeLabelExpectedTypesMap: this.fileValidateTypeLabelExpectedTypesMap,
            fileValidateTypeDetectType: (source, type) => new Promise((resolve, reject) => {
                if (type) {
                    // Recognized mime type
                    if (this.acceptedFileTypes.find(fileType => fileType === type)) {
                        // Accepted mime type
                        resolve(type);
                    } else {
                        // Rejected mime type
                        reject(type);
                    }
                } else {
                    // Unrecognized mime type, looking for a file extension
                    const uploadedFileExtension = source.name.split('.').pop();
                    // Checking if the file extension is accepted
                    const isAllowed = this.acceptedFileTypes.find(fileType => fileType.split('.').pop() === uploadedFileExtension) !== undefined;

                    if (isAllowed) {
                        // Resolve with our "false" mime type
                        resolve('allowed/extension');
                    } else {
                        // Even the extension is not accepted, reject
                        reject('.' + uploadedFileExtension);
                    }
                }
            }),
            // ** Plugin : File image preview
            allowImagePreview: this.allowImagePreview,
            imagePreviewMinHeight: this.imagePreviewMinHeight,
            imagePreviewMaxHeight: this.imagePreviewMaxHeight,
            imagePreviewHeight: this.imagePreviewHeight,
            imagePreviewMaxFileSize: this.imagePreviewMaxFileSize,
            imagePreviewTransparencyIndicator: this.imagePreviewTransparencyIndicator,
            imagePreviewMaxInstantPreviewFileSize: this.imagePreviewMaxInstantPreviewFileSize,
            imagePreviewMarkupShow: this.imagePreviewMarkupShow,
            // ** Plugin : File image resize
            allowImageResize: this.allowImageResize,
            imageResizeTargetWidth: this.imageResizeTargetWidth,
            imageResizeTargetHeight: this.imageResizeTargetHeight,
            imageResizeMode: this.imageResizeMode,
            imageResizeUpscale: this.imageResizeUpscale,
            // ** Plugin : File image crop
            allowImageCrop: this.allowImageCrop,
            imageCropAspectRatio: this.imageCropAspectRatio,
            // ** Plugin : File image edit
            allowImageEdit: false,
            imageEditIconEdit: '<svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"><path d="M8.5 17h1.586l7-7L15.5 8.414l-7 7V17zm-1.707-2.707l8-8a1 1 0 0 1 1.414 0l3 3a1 1 0 0 1 0 1.414l-8 8A1 1 0 0 1 10.5 19h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 .293-.707z" fill="currentColor" fill-rule="nonzero"></path></svg>'
        };
    }
}
