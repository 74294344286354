import { Controller } from '@hotwired/stimulus';
import IMask from 'imask';
export default class MoneyIntlInput extends Controller {
    connect() {
        super.connect();
        this.element.onfocus = () => {
            if (parseInt(this.element.value) === 0) {
                this.element.select();
            }
        };
        this.initMask();
    }
    initMask() {
        // Simulation d'un nombre depuis `Intl` pour récupérer la composition
        // d'un nombre à virgule dans la langue en cours
        const langFormat = new Intl.NumberFormat();
        const result = langFormat.format(123456.789);
        const thousandsSeparator = typeof result[3] !== 'number' ? result[3] : '';
        const radix = typeof result[7] !== 'number' ? result[7] : '.';
        let mapToRadix = [];
        if (radix === ',') {
            // Format comme FR.
            // Si ajout point, transforme en virgule automatiquement
            mapToRadix.push('.');
        }
        IMask(this.element, {
            mask: Number,
            scale: this.scaleValue,
            thousandsSeparator: thousandsSeparator,
            padFractionalZeros: this.padFractionalZerosValue,
            normalizeZeros: this.normalizeZerosValue,
            radix: radix,
            mapToRadix: mapToRadix // symbols to process as radix
        });
    }
}
MoneyIntlInput.values = {
    scale: { type: Number, default: 2 },
    padFractionalZeros: { type: Boolean, default: false },
    normalizeZeros: { type: Boolean, default: true }
};
