export class TablerMouseContextMenu {
    constructor(items = [], options = {}, container = document.body) {
        this.dropdown = undefined;
        this.dropdownMenuColumns = undefined;
        this.focusout = undefined;
        this.items = items;
        this.options = options;
        this.container = container;
    }
    set items(items) {
        this._items = items;
    }
    get items() {
        return this._items;
    }
    addItem(item, column = 0) {
        if (Array.isArray(this.items[column]) === false) {
            throw new Error(`Undefined column '${column}' in items!`);
        }
        this.items[column].push(item);
    }
    hasItems() {
        return this.items.flat().length > 0;
    }
    show(x, y) {
        this.hide();
        if (this.hasItems() === false) {
            console.warn('Cannot show a menu without any item');
            return;
        }
        this.render(x, y);
        this.container.appendChild(this.focusout);
        this.container.appendChild(this.dropdown);
    }
    hide() {
        if (this.focusout) {
            this.focusout.remove();
        }
        if (this.dropdown) {
            this.dropdown.remove();
        }
    }
    render(x, y) {
        const focusout = document.createElement('div');
        focusout.classList.add('modal-backdrop', 'fade', 'show');
        focusout.addEventListener('mousedown', e => {
            dropdown.remove();
            focusout.remove();
        });
        focusout.addEventListener('contextmenu', e => {
            e.preventDefault();
        });
        this.focusout = focusout;
        const dropdown = document.createElement('div');
        dropdown.classList.add('dropdown-menu');
        dropdown.style.display = 'block';
        dropdown.style.position = 'absolute';
        dropdown.style.top = y + 'px';
        dropdown.style.left = x + 'px';
        // dropdown.style.width = '250px';
        dropdown.style.zIndex = '1055';
        const dropdownMenuColumns = document.createElement('div');
        dropdownMenuColumns.classList.add('dropdown-menu-columns');
        dropdown.appendChild(dropdownMenuColumns);
        this.items.forEach(columnItems => {
            const dropdownMenuColumn = document.createElement('div');
            dropdownMenuColumn.classList.add('dropdown-menu-column');
            columnItems.forEach(item => {
                item.render(dropdownMenuColumn, focusout);
            });
            dropdownMenuColumns.appendChild(dropdownMenuColumn);
        });
        this.dropdown = dropdown;
        this.dropdownMenuColumns = dropdownMenuColumns;
    }
}
