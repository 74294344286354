import Clipboard from "stimulus-clipboard"

export default class extends Clipboard {
    connect() {
        super.connect()

        this.originalText = this.buttonTarget.innerHTML
    }

    copy (event) {
        event.preventDefault();

        if (window.isSecureContext === false) {
            throw new Error('To be able to use `Stimulus Clipboard` this requires a secure origin — either HTTPS or localhost (or disabled by running Browser with a flag) !');
        }

        navigator.clipboard
            .write(this.sourceTarget.innerHTML)
            .then(r => this.copied())
        ;
    }

    copied () {
        if (!this.hasButtonTarget) return;

        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        // this.buttonTarget.innerText = this.data.get('successContent')
        this.buttonTarget.innerHTML = this.data.get('successContent');

        this.timeout = setTimeout(() => {
            this.buttonTarget.innerHTML = this.originalText;
        }, this.successDurationValue);
    }
}
