import { ContextMenuItem } from './ContextMenuItem';
export class ContextSubMenuItem extends ContextMenuItem {
    constructor(name, items = []) {
        super();
        this.name = name;
        this.items = items;
    }
    addItem(item) {
        this.items.push(item);
    }
    render(dropdown, focusout) {
        const dropend = document.createElement('div');
        dropend.classList.add('dropend');
        const dropdown_item = document.createElement('a');
        this._initMenuItem(dropdown_item);
        this._setItemContent(dropdown_item, this.name);
        dropdown_item.classList.add('dropdown-toggle');
        dropdown_item.href = '#';
        dropdown_item.setAttribute('role', 'button');
        dropdown_item.setAttribute('data-bs-toggle', 'dropdown');
        dropdown_item.setAttribute('data-bs-auto-close', 'outside');
        dropend.appendChild(dropdown_item);
        const dropdown_menu = document.createElement('div');
        dropdown_menu.classList.add('dropdown-menu');
        this.items.forEach(item => {
            item.render(dropdown_menu, focusout);
        });
        dropend.appendChild(dropdown_menu);
        dropdown.appendChild(dropend);
    }
}
