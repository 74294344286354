import {Controller} from '@hotwired/stimulus';
import * as FilePond from "filepond";
import 'filepond/dist/filepond.min.css';
import fr_fr from 'filepond/locale/fr-fr';
fr_fr.labelIdle= 'Faites glisser vos fichiers ici ou <span class = "filepond--label-action"> Parcourir <span>';


// Import the Image Preview plugin
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
// Import the File Type Validation plugin
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// Import the plugin code
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import TablerModal from "../js/modules/TablerModal";
import TablerMouseContextMenu, {
    ContextMenuItemLink,
    ContextMenuItemSeparator,
    ContextSubMenuItem
} from "../js/modules/TablerMouseContextMenu";

const CST_Item_SelectedClass = 'active';

export default class GuiFileExplorer extends Controller {

    static values = {
        rootUrl: String, // Url listant les fichiers à la racine
        uploadUrl: String, // Url pour l'upload des éléments
        createFolderUrl: String, // Url pour la création d'un dossier
        searchItemUrl: String, // Url pour rechercher des items
        moveItemUrl: String, // Url pour le déplacer un item
        renameItemUrl: String, // Url pour le renommage d'un item
        publicItemUrl: String, // Url pour publique d'un item
        deleteItemUrl: String, // Url pour la suppression d'un item
        acceptedMimeTypes: Array, // Liste des types de fichiers acceptés
        fileValidateTypeLabelExpectedTypesMap: Object, // Allows mapping the file type to a more visually appealing label, { 'image/jpeg': '.jpg' } will show .jpg in the expected types label
    };

    static targets = [
        'itemList', // Conteneur HTML pour le listing des fichiers
        'filepond', // Input file HTML pour filepond upload
    ];

    /** @type FilePond */
    pond = undefined;

    /** @type {TablerMouseContextMenu} */
    _contextmenu = new TablerMouseContextMenu();

    /** @type {JsFileExplorerItem} */
    _currentDir = undefined;
    /** @type {JsFileExplorerItem} */
    _currentParentDir = undefined;

    /** @type {Set<JsFileExplorerItem>} */
    _itemsData = new Set();

    /** @type {Set<JsFileExplorerPathItem>} */
    _paths = new Set();

    /**
     * Retourne le premier element du chemin (breadcrumb)
     * @return {JsFileExplorerPathItem}
     */
    get firstPath() {
        return this._paths.values().next().value;
    }

    /**
     * Retourne le dernier element du chemin (breadcrumb)
     * @return {JsFileExplorerPathItem}
     */
    get lastPath() {
        return this._paths.size > 0 ? [...this._paths].pop() : undefined;
    }

    /** @type {Set<JsFileExplorerPathItem>} */
    _pathHistoric = new Set();
    _pathHistoricIndex = 0;

    /**
     * Retourne le dernier chemin de l'historique
     * @return {JsFileExplorerPathItem}
     */
    get lastPathHistoric() {
        return this._pathHistoric.size > 0 ? [...this._pathHistoric].pop() : undefined;
    }

    /**
     * Donne le point de départ/racine de l'explorateur
     * @return {URL}
     */
    get rootUrl() {
        return new URL(this.rootUrlValue, window.location.origin)
    }

    /**
     * Génère le breadcrumb de l'élément de root
     * @return {JsFileExplorerPathItem}
     */
    get rootItemPath() {
        return new JsFileExplorerPathItem('(/)', this.rootUrl);
    }

    /**
     * Retourne les données des items pour l'explorateur
     * @return {Set<JsFileExplorerItem>}
     */
    get itemsData() {
        return this._itemsData;
    }

    /**
     * Ajoute une donnée d'item à l'explorateur
     * @param {JsFileExplorerItem} jsFileExplorerItem
     */
    addItemData(jsFileExplorerItem) {
        this._itemsData.add(jsFileExplorerItem)
    }

    /**
     * Récupère un item de l'explorateur (depuis les données)
     * @param {string} uuid
     * @param {boolean} printError
     * @return {null|JsFileExplorerItem}
     */
    getItemData(uuid, printError = true) {
        const item = Array.from(this.itemsData).find(el => el.uuid === uuid);
        if (item) return item;
        if (printError) console.error(`item uuid: ${uuid} is unknown!`);
        return null;
    }

    /**
     * Donne le conteneur HTML de l'explorateur
     * @return {HTMLElement|null}
     */
    get itemListContainer() {
        return this.hasItemListTarget ? this.itemListTarget : null;
    }

    /**
     * Donne l'élément HTML qui est sélectionné
     * @return {HTMLElement}
     */
    get selectedItem() {
        return this.itemListContainer.querySelector(`.item.${CST_Item_SelectedClass}`);
    }

    /**
     * Donne l'uuid de l'item sélectionné
     * @return {*|null}
     */
    get selectedItemUuid() {
        return this.selectedItem?.dataset.uuid || null;
    }

    /**
     * Donne les data de l'item sélectionné
     * @return {JsFileExplorerItem|null}
     */
    get selectedItemData() {
        const uuid = this.selectedItemUuid;
        if (!uuid) return null;
        return this.getItemData(uuid, true);
    }

    /**
     * Architecture permettant de gérer les le bandeau de navigation
     * @type {{children: {navNext: *, search: {input: {timeout: *, element: *}, icon: *}, breadcrumb: *, refresh: *, navPrev: *, home: *}, element: *}}
     * @private
     */
    _navTools = {
        element: undefined,
        children: {
            navPrev: undefined,
            navNext: undefined,
            home: undefined,
            breadcrumb: undefined,
            refresh: undefined,
            search: {
                input: {
                    element: undefined,
                    timeout: undefined,
                },
                icon: undefined,
            },
        }
    }

    /**
     * Donne le conteneur HTML du bandeau de navigation
     * @type {{upload: {children: {filepond: *}, element: *}, bars: {element: *}, element: *, home: {new: {children: {folder: *}, element: *}, selection: {children: {all: *, invert: *, none: *}, element: *}, organize: {children: {item_move: *, item_rename: *, item_delete: *}, element: *}, open: {children: {item: *, properties: *}, element: *}, element: *}}}
     * @private
     */
    _toolbar = {
        element: undefined,
        bars: {
            element: undefined,
        },
        home: {
            element: undefined,
            organize: {
                element: undefined,
                children: {
                    item_move: undefined,
                    item_rename: undefined,
                    item_delete: undefined,
                },
            },
            new: {
                element: undefined,
                children: {
                    folder: undefined,
                },
            },
            open: {
                element: undefined,
                children: {
                    item: undefined,
                    properties: undefined,
                },
            },
            selection: {
                element: undefined,
                children: {
                    all: undefined,
                    invert: undefined,
                    none: undefined,
                },
            },
        },
        upload: {
            element: undefined,
            children: {
                filepond: undefined,
            },
        },
    }

    /**
     * Vérifie les prérequis du module
     * @private
     */
    _abortIfMissingRequiredConfig() {

        let errors = new Set();

        if (this.itemListContainer === null) {
            errors.add("'gui-file-explorer' should have `itemList` target!");
        }

        if (this.hasUploadUrlValue || this.hasFilepondTarget) {
            // Upload possible

            if (this.hasFilepondTarget === false) {
                errors.add("'gui-file-explorer' should have `filepond` (Target) if `uploadUrl` (Param) exist!");
            }

            if (this.hasFilepondTarget === false) {
                errors.add("'gui-file-explorer' should have `uploadUrl` (Param) if `filepond` (Target) exist!");
            }
        }

        if (errors.size > 0) {
            console.error(this.element);
            throw new Error(Array.from(errors).join("\n"));
        }
    }

    initialize() {
        super.initialize();
        this._abortIfMissingRequiredConfig();

        // ** Bind de `this` (module) sur les events ** //
        this._onDocumentkeyup = onDocumentKeyup.bind(this);

        this._onItemListMouseup = onItemListMouseup.bind(this);

        this._onItemMousedown = onItemMousedown.bind(this);
        this._onItemMouseup = onItemMouseup.bind(this);
        this._onItemClick = onItemClick.bind(this);
        this._onItemDblClick = onItemDblClick.bind(this);

        this._onHistoricPrevClick = onHistoricPrevClick.bind(this);
        this._onHistoricNextClick = onHistoricNextClick.bind(this);

        this._onHomeClick = onHomeClick.bind(this);

        this._onRefreshClick = onRefreshClick.bind(this);

        this._onSearchChange = onSearchChange.bind(this);

        this._onCreateFolderClick = onCreateFolderClick.bind(this);
        // ******************************************** //

        this.itemListContainer.oncontextmenu = (e) => e.preventDefault();
        this.itemListContainer.onmouseup = this._onItemListMouseup;
        document.onkeyup = this._onDocumentkeyup;


        const initNavtools = () => {
            const navtools = this.element.querySelector('.fe-navtools');
            const prev = navtools.querySelector('.fe-navtool-prev');
            prev.onclick = (event) => this._onHistoricPrevClick(event, prev);
            this._navTools.children.navPrev = prev;

            const next = navtools.querySelector('.fe-navtool-next');
            next.onclick = (event) => this._onHistoricNextClick(event, next);
            this._navTools.children.navNext = next;

            const home = navtools.querySelector('.fe-navtool-home');
            home.onclick = (event) => this._onHomeClick(event, home);
            this._navTools.children.home = home;

            const breadcrumb = navtools.querySelector('.breadcrumb');
            this._navTools.children.breadcrumb = breadcrumb;

            const refresh = navtools.querySelector('.fe-navtool-refresh');
            refresh.onclick = (event) => this._onRefreshClick(event, refresh);
            this._navTools.children.refresh = refresh;

            const searchInput = navtools.querySelector('.fe-navtool-search input');
            searchInput.onkeyup = (event) => this._onSearchChange(event, searchInput);
            const searchIcon = searchInput.parentNode.querySelector('.input-icon-addon');
            this._navTools.children.search.input.element = searchInput;
            this._navTools.children.search.icon = searchIcon;

            this._navTools.element = navtools;
        };

        initNavtools();

        const initToolbar = () => {
            const toolbar = this.element.querySelector('.fe-toolbar');
            this._toolbar.element = toolbar;


            this._toolbar.bars.element = toolbar.querySelectorAll('#tabs-bars');


            const home = toolbar.querySelector('#tabs-home');
            this._toolbar.home.element = home;

            const organize = home.querySelector('.fe-toolbar-home-organize');
            this._toolbar.home.organize.element = organize;

            const organize_item_move = organize.querySelector('.fe-toolbar-home-organize-item-move');
            this._toolbar.home.organize.children.item_move = organize_item_move;

            const organize_item_rename = organize.querySelector('.fe-toolbar-home-organize-item-rename');
            organize_item_rename.onclick = () => this.editItem(this.selectedItemUuid);
            this._toolbar.home.organize.children.item_rename = organize_item_rename;

            const organize_item_delete = organize.querySelector('.fe-toolbar-home-organize-item-delete');
            organize_item_delete.onclick = () => this.deleteItem(this.selectedItemUuid);
            this._toolbar.home.organize.children.item_delete = organize_item_delete;


            const newEl = home.querySelector('.fe-toolbar-home-new');
            this._toolbar.home.new.element = newEl;

            const new_folder = newEl.querySelector('.fe-toolbar-home-new-folder');
            new_folder.onclick = () => this._onCreateFolderClick();
            this._toolbar.home.new.children.folder = new_folder;


            const open = home.querySelector('.fe-toolbar-home-open');
            this._toolbar.home.open.element = open;

            const open_item = open.querySelector('.fe-toolbar-home-open-item');
            open_item.onclick = () => {
                const itemData = this.getItemData(this.selectedItemUuid);
                if (itemData.type === 'file') {
                    this.openFile(this.selectedItemUuid);
                } else {
                    this.openDir(this.selectedItemUuid);
                }
            };
            this._toolbar.home.open.children.item = open_item;


            const open_properties = open.querySelector('.fe-toolbar-home-open-properties');
            open_properties.onclick = () => this.showProperties(this.selectedItemUuid);
            this._toolbar.home.open.children.properties = open_properties;


            const selection = home.querySelector('.fe-toolbar-home-selection');
            this._toolbar.home.selection.element = selection;

            const selection_all = selection.querySelector('.fe-toolbar-home-selection-all');
            selection_all.onclick = () => this.selectAll();
            this._toolbar.home.selection.children.all = selection_all;

            const selection_invert = selection.querySelector('.fe-toolbar-home-selection-invert');
            selection_invert.onclick = () => this.invertSelection();
            this._toolbar.home.selection.children.invert = selection_invert;

            const selection_none = selection.querySelector('.fe-toolbar-home-selection-none');
            selection_none.onclick = () => this.unselectAll();
            this._toolbar.home.selection.children.none = selection_none;


            const upload = toolbar.querySelector('#tabs-upload');
            this._toolbar.upload = upload;


            const filepond = upload.querySelector('.fe-toolbar-upload-filepond');
            this._toolbar.upload.filepond = filepond;
        };

        initToolbar();

        if (this.hasUploadUrlValue) {
            /** Configuration de l'uploader (Filepond) **/

            // FilePond.registerPlugin(FilePondPluginImagePreview);
            FilePond.registerPlugin(FilePondPluginFileValidateType);
            FilePond.registerPlugin(FilePondPluginFileValidateSize);
            FilePond.setOptions(fr_fr);

            const acceptedFileTypes = this.acceptedMimeTypesValue ?? ['*'];
            this.pond = FilePond.create(this.filepondTarget, {
                // server: this.uploadUrlValue,
                server: {
                    url: this.uploadUrlValue,
                    revert: null,
                    restore: null,
                    load: null,
                    fetch: null,
                },
                allowMultiple: true,
                allowReplace: false,
                allowRevert: false,
                allowRemove: false,
                name: 'file_element',
                checkValidity: true,
                maxFileSize: '3000MB',
                allowFileTypeValidation: !!this.acceptedMimeTypesValue,
                acceptedFileTypes: acceptedFileTypes,
                fileValidateTypeLabelExpectedTypesMap: this.fileValidateTypeLabelExpectedTypesMapValue ?? [],
                fileValidateTypeDetectType: (source, type) => new Promise((resolve, reject) => {
                    if (type) {
                        // Recognized mime type
                        if (acceptedFileTypes.find(fileType => fileType === type)) {
                            // Accepted mime type
                            resolve(type);
                        } else {
                            // Rejected mime type
                            reject(type);
                        }
                    } else {
                        // Unrecognized mime type, looking for a file extension
                        const uploadedFileExtension = source.name.split('.').pop();
                        // Checking if the file extension is accepted
                        const isAllowed = acceptedFileTypes.find(fileType => fileType.split('.').pop() === uploadedFileExtension) !== undefined;

                        if (isAllowed) {
                            // Resolve with our "false" mime type
                            resolve('allowed/extension');
                        } else {
                            // Even the extension is not accepted, reject
                            reject('.' + uploadedFileExtension);
                        }
                    }
                }),
            });

            this.pond.element.addEventListener('FilePond:processfile', (e) => {
                // Refresh du listing après upload
                this.refresh();
            });

            this.pond.element.addEventListener('FilePond:error', (e) => {
                // Bug d'affichage de Filepond, on force une seule fois une extension à l'affichage de l'erreur
                e.target.querySelectorAll('.filepond--file-status-sub').forEach(el => {
                    const html = el.innerHTML;
                    el.innerHTML = [...new Set(html.split(' '))].join(' ');
                });
            });
        }

        // Init du module avec le chemin root comme racine
        this._paths.add(this.rootItemPath);
        this.refresh();
    }

    /**
     * Peuple les données depuis une URL
     * @param {URL} url
     * @return {Promise<boolean>}
     * @private
     */
    _populateData(url) {
        return new Promise(resolve => {

            // Reset de l'état actuel des données et du chemin
            this._paths = new Set();
            this._itemsData = new Set();

            fetch(url.toString())
                .then(response => response.json())
                .then(json => {
                    // console.log('populateData', json);

                    // Récupération du dossier parent
                    this._currentParentDir = json.parent ? JsFileExplorerItem.fromJson(json.parent) : null;
                    // Récupération du dossier actuel (lui-même)
                    this._currentDir = JsFileExplorerItem.fromJson(json.self);

                    // Pour tous les paths (chemin d'accès) du dossier actuel
                    json.paths.forEach(json_path => {
                        this._paths.add(JsFileExplorerPathItem.fromJson(json_path));
                    });

                    // Pour tous les items du dossier actuel
                    json.items.forEach(json_item => {
                        this.addItemData(JsFileExplorerItem.fromJson(json_item));
                    });

                    resolve(true);
                })
            ;
        });
    }

    /**
     * Permet d'aller dans un dossier connu de l'explorateur
     * @param {string} uuid
     */
    openDir(uuid) {
        // Récupération du dossier
        const itemData = this.getItemData(uuid);

        if (itemData) {
            if (itemData.type === "dir") {
                // Création du path item pour le dossier sélectionné
                const pathItem = new JsFileExplorerPathItem(itemData.name, itemData.folderUrl);

                // Clean de l'input de recherche
                this._navTools.children.search.input.element.value = '';

                // Gestion de l'historique
                this._pathHistoric.add(pathItem);
                this._pathHistoricIndex++;

                // Ajout du path
                this._paths.add(pathItem);

                this.refresh();
            } else {
                console.error('item is not of type `dir`!');
            }
        }
    }

    /**
     * Ouvre le fichier connu de l'explorateur
     */
    openFile(uuid) {
        // Récupération du fichier
        const itemData = this.getItemData(uuid);

        if (itemData) {
            if (itemData.type === "file") {
                window.open(itemData.mediaUrl, '_blank').focus();
            } else {
                console.error('item is not of type `file`!');
            }
        }
    }

    /**
     * Permet de retourner dans le dossier racine
     */
    goHome() {
        // Clean de l'historique
        this._pathHistoricIndex = 0;

        // Clean du breadcrumb
        this._paths.clear();
        this._paths.add(this.rootItemPath);

        // Clean de l'input de recherche
        this._navTools.children.search.input.element.value = '';

        this.refresh();
    }

    /**
     * Permet de retourner au dossier précédent
     */
    goBack() {
        if (this._pathHistoric.size > 0) {
            this._pathHistoricIndex--;
            const prevPath = this._pathHistoric[this._pathHistoricIndex];

            // Suppression de dernier element du breadcrumb
            this._paths.delete(this.lastPath);

            this.refresh();
        }
    }

    /**
     * Permet d'aller au dossier suivant de l'historique
     */
    goForward() {
        if (this._pathHistoric.size > 1) {
            this._pathHistoricIndex++;
            const nextPath = this._pathHistoric[this._pathHistoricIndex];

            this._paths.add(nextPath);

            this.refresh();
        }
    }

    /**
     * Permet de rechercher un item dans l'explorateur
     * @param {string} searchString
     */
    search(searchString) {
        // Clean du breadcrumb
        this._paths.clear();

        if (searchString) {
            // Affichage de la recherche dans le breadcrumb
            this._paths.add(
                new JsFileExplorerPathItem(
                    'Résultat de recherche <<' + searchString + '>> dans "' + this._currentDir.name + '"',
                    new URL(this.searchUrlValue, window.location.href)
                )
            );
            this._refreshBreadcrumb();

            // Création du `body` de la requête
            const formData = new FormData();
            formData.append('folderUuid', this._currentDir.uuid);
            formData.append('search', searchString);

            // POST de la requête
            fetch(this.searchItemUrlValue, {
                method: 'POST',
                body: formData,
            }).then(res => res.json())
                .then(json => {
                    // Clean des éléments actuels affichés
                    this._itemsData.clear();

                    json.result.forEach(json_item => {
                        this.addItemData(JsFileExplorerItem.fromJson(json_item));
                    });

                    this._renderListFromData();
                });
        } else {
            // Aucune recherche
            this.goHome();
        }
    }

    /**
     * Permet le `reload` des éléments de l'explorateur dans le dernier dossier du breadcrumb
     * @param {boolean} printMessage
     */
    refresh(printMessage = false) {

        // Récupération du dernier URL présent dans les chemins
        const lastPath = this.lastPath;
        if (lastPath === undefined) return;

        this._populateData(lastPath.url)
            .then(() => {
                this._refreshPond();
                this._refreshHistoric();
                this._refreshBreadcrumb();
                this._refreshList();
                this._refreshToolbarActions();
            })
        ;

        if (printMessage) this._printMessage("Élément(s) rafraichis.");
    }

    /**
     * Actualise `Filepond` pour uploader dans le dossier courant
     * @private
     */
    _refreshPond() {
        if (this.pond) {
            this.pond.setOptions({
                server: {
                    process: {
                        url: this.uploadUrlValue,
                        headers: {
                            'x-folder-uuid': this._currentDir.uuid,
                        }
                    },
                }
            })
        }
    }

    /**
     * Actualise l'historique du chemin courant
     * @private
     */
    _refreshHistoric() {
        this._navTools.children.navPrev.classList
            .toggle('disabled', this._pathHistoricIndex === 0 || this._pathHistoric.size < 1);

        this._navTools.children.navNext.classList
            .toggle('disabled', this._pathHistoricIndex === 0 || this.lastPath === this.lastPathHistoric);
    }

    /**
     * Actualise le breadcrumb
     * @private
     */
    _refreshBreadcrumb() {
        const breadcrumbContainer = this._navTools.children.breadcrumb;
        // Clean du breadcrumb
        breadcrumbContainer.innerHTML = "";

        // Pour tous les elements du breadcrumb (Data)
        const pathItems = [...this._paths];
        pathItems.forEach((pathItem, index) => {
            const li = document.createElement('li');
            li.classList.add('breadcrumb-item');

            const a = document.createElement('a');
            a.innerText = pathItem.name;
            if (index === 0) a.innerText += " (/)";

            a.onclick = () => {
                let cleanEl = false;
                // Pour tous les éléments du breadcrumb
                this._paths.forEach(existingPathItem => {
                    if (cleanEl) this._paths.delete(existingPathItem);

                    // Si l'élément courant est égal à l'élément cliqué, on clean
                    if (existingPathItem.url === pathItem.url) cleanEl = true;
                });

                this.refresh();
            };

            li.appendChild(a);
            breadcrumbContainer.appendChild(li);
        });
    }

    /**
     * Actualise la liste des éléments
     * @private
     */
    _refreshList() {
        this._renderListFromData();
    }

    /**
     * Actualise la liste des éléments depuis les data
     * @private
     */
    _renderListFromData() {
        // Clean complet de la liste
        this.itemListContainer.innerHTML = '';

        // Affichage du nombre d'éléments
        this.element.querySelector('.fe-items-count').innerText = this._itemsData.size;

        if (this.itemsData.size > 0) {
            this.itemsData.forEach(jsFileExplorerItem => {
                const itemHtml = jsFileExplorerItem.toHTMLElement();
                itemHtml.onmouseup = (event) => this._onItemMouseup(event, itemHtml);
                itemHtml.onmousedown = (event) => this._onItemMousedown(event, itemHtml);
                itemHtml.onclick = (event) => this._onItemClick(event, itemHtml);
                itemHtml.ondblclick = (event) => this._onItemDblClick(event, itemHtml);

                this.itemListContainer.append(itemHtml);
            });
        } else {
            // Aucun élément
            this.itemListContainer.innerHTML = `            
                <div class="empty p-3">
                    <p class="empty-title">Dossier vide</p>
                    <p class="empty-subtitle text-muted">
                        Aucun élément n'a été trouvé.
                    </p>
                </div>
            `;
        }
    }

    /**
     * Actualise les actions disponibles de la toolbar
     * @private
     */
    _refreshToolbarActions() {
        const itemData = this.selectedItemData;

        this._toolbar.home.new.children.folder.disabled = false;

        this._toolbar.home.selection.children.none.disabled = false;

        if (itemData) {
            this._toolbar.home.organize.children.item_move.disabled = itemData.type === 'dir';
            this._toolbar.home.organize.children.item_rename.disabled = itemData.renameable === false;
            this._toolbar.home.organize.children.item_delete.disabled = itemData.deletable === false;

            this._toolbar.home.open.children.item.disabled = false;
            this._toolbar.home.open.children.properties.disabled = false;

            // this._toolbar.home.selection.children.all.disabled = false;
            // this._toolbar.home.selection.children.invert.disabled = false;
        } else {
            this._toolbar.home.organize.children.item_move.disabled = true;
            this._toolbar.home.organize.children.item_rename.disabled = true;
            this._toolbar.home.organize.children.item_delete.disabled = true;

            this._toolbar.home.open.children.item.disabled = true;
            this._toolbar.home.open.children.properties.disabled = true;

            this._toolbar.home.selection.children.all.disabled = true;
            this._toolbar.home.selection.children.invert.disabled = true;
        }
    }

    /**
     * Créer un nouveau dossier avec le nom donné
     * @param {string} folderName
     */
    createNewFolder(folderName) {
        if (this.hasCreateFolderUrlValue === false)
            throw new Error('`createFolderUrl` (Param) is required to use `createNewFolder` function');

        const url = new URL(this.createFolderUrlValue, window.location.origin);

        const body = new FormData();
        body.append('parentFolderUuid', this._currentDir.uuid);
        body.append('newFolderName', folderName);

        fetch(url.toString(), {
            method: 'POST',
            body: body
        }).then(async response => {
            if (response.status === 200) {
                this.refresh();
            } else {
                const data = await response.json();
                this._printMessage(data.error,6000, true)
            }
        }).catch(error => {
            console.error(url, error);
            alert('Création nouveau dossier - Une erreur est survenue !');
        });
    }

    /**
     * Affiche un message en bas de module
     * @param {string} message
     * @param timeOut
     * @param asError
     * @private
     */
    _printMessage(message = '', timeOut = 3000, asError = false) {
        const errorClasses = [
            'text-danger', 'animate__animated', 'animate__flash', 'animate__infinite', 'animate__slow'
        ]

        const messageContainer = this.element.querySelector('.fe-message');
        if (asError) messageContainer.classList.add(...errorClasses);
        messageContainer.innerHTML = message;

        if (message) {
            setTimeout(() => {
                messageContainer.classList.remove(...errorClasses);
                messageContainer.innerHTML = '';
            }, timeOut);
        }
    }

    /**
     * Sélectionne les items affichés depuis leurs uuid
     * @param {[string]} uuids
     */
    selectItems(uuids = []) {
        const container = this.itemListContainer;

        // Suppression de la class de tous les éléments
        container.querySelectorAll(`.${CST_Item_SelectedClass}`).forEach(el => {
            el.classList.remove(CST_Item_SelectedClass);
        });

        // Pour tous les items avec uuid
        container.querySelectorAll('.item[data-uuid]').forEach(el => {
            // Si uuid correspond
            if (uuids.includes(el.dataset.uuid)) {
                // Ajout class 'sélectionné'
                el.classList.add(CST_Item_SelectedClass);
            }
        });

        this._refreshToolbarActions();
    }

    /**
     * Sélectionne un seul item
     * @param {?string} uuid
     */
    selectItem(uuid = null) {
        this.selectItems(uuid ? [uuid] : []);
    }

    /**
     * Sélectionne tous les items
     */
    selectAll() {
        throw new Error('Not implemented');
    }

    /**
     * Inverse la sélection des items
     */
    invertSelection() {
        throw new Error('Not implemented');
    }

    /**
     * Dé-sélectionne tous les items
     */
    unselectAll() {
        this.selectItem();
    }

    /**
     * Rentre en mode édition sur un item
     * @param {string} uuid
     */
    editItem(uuid) {
        const itemData = this.getItemData(uuid);
        if (itemData) {

            // Récupération de sa correspondance dans le DOM HTML
            const itemHTML = this.itemListContainer.querySelector(`.item[data-uuid="${uuid}"]`);
            const nameDisplay = itemHTML.querySelector('.fe-item-name');

            let input;
            /**
             * Remplace le nom de l'item par un input HTML
             */
            const showInput = () => {
                input = document.createElement('input');
                input.classList.add('form-control')
                input.name = 'name';
                input.value = itemData.name;
                input.onblur = postNewName;
                input.onkeyup = ({key}) => {
                    if (key === "Enter") {
                        postNewName();
                    } else if (key === "Escape") {
                        hideInput();
                    }
                }

                nameDisplay.hidden = true;
                nameDisplay.after(input);
                input.focus();
                input.select();
            }

            /**
             * Cache l'input
             */
            const hideInput = () => {
                itemData.name = input.value;
                nameDisplay.innerHTML = input.value;
                input.remove();
                nameDisplay.hidden = false;
            }

            /**
             * Envoi le nouveau nom de l'item
             */
            const postNewName = () => {
                const url = new URL(this.renameItemUrlValue, window.location.origin);

                const body = new FormData();
                body.append('uuid', uuid);
                body.append('newName', input.value);

                fetch(url.toString(), {
                    method: 'POST',
                    body: body
                })
                    .then(response => {
                        if (response.status === 200) {
                            hideInput();

                            this.refresh();
                        } else {
                            throw new Error('Erreur renommage item');
                        }
                    })
                    .catch(error => {
                        alert('Edition item - Erreur lors de édition du nom !');
                    });
            }

            // Affiche l'input
            showInput();
        }
    }

    /**
     * Rentre en mode édition sur un item sélectionné
     */
    editSelectedItem() {
        this.editItem(this.selectedItemUuid);
    }

    /**
     * Change la visibilité d'un item sélectionné
     * @param {string} uuid
     * @param printError
     */
    togglePublic(uuid, printError = true) {
        if (this.hasPublicItemUrlValue === false) {
            return;
        }

        const itemData = this.getItemData(uuid, printError);
        if (itemData && itemData.locked === false) {
            const url = new URL(this.publicItemUrlValue, window.location.origin);

            const body = new FormData();
            body.append('uuid', uuid);

            fetch(url.toString(), {
                method: 'POST',
                body: body
            }).then(response => {
                if (response.status === 200) {
                    this.refresh();
                } else {
                    throw new Error('Erreur toggle publique item');
                }
            }).catch(error => {
                alert('Publique item - Erreur lors du publique !');
            });
        }
    }

    /**
     * Change la visibilité d'un item sélectionné
     */
    togglePublicSelectedItem() {
        this.togglePublic(this.selectedItemUuid);
    }

    /**
     * Supprime un item depuis un uuid avec confirmation
     * @param {string} uuid
     * @param printError
     */
    deleteItem(uuid, printError = true) {
        const itemData = this.getItemData(uuid, printError);
        if (itemData && itemData.deletable) {
            (new TablerModal())
                .confirm(`Êtes-vous sûr de supprimer l'élément '${itemData.name}' ?`)
                .then(confirm => {
                    if (confirm === false) return;

                    const url = new URL(this.deleteItemUrlValue, window.location.origin);

                    const body = new FormData();
                    body.append('uuid', uuid);

                    fetch(url.toString(), {
                        method: 'POST',
                        body: body
                    }).then(response => {
                        if (response.status === 200) {
                            // this.renderListFromUrl(itemData.parentFolderUrl);
                            this.refresh();
                        } else {
                            throw new Error('Erreur suppression item');
                        }
                    }).catch(error => {
                        alert('Suppression item - Erreur lors de suppression !');
                    });
                });
        }
    }

    /**
     * Supprime l'item sélectionné
     */
    deleteSelectedItem() {
        this.deleteItem(this.selectedItemUuid, false);
    }

    /**
     * Affiche les propriétés d'un item
     * @param {string} uuid
     */
    showProperties(uuid) {
        const itemData = this.getItemData(uuid);
        if (itemData) {
            const myOffcanvas = document.getElementById('offcanvasFileProperties')
            const body = myOffcanvas.getElementsByClassName('offcanvas-body')[0];

            const localeDateStringOptions = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            };
            const type = itemData.type === "dir" ? 'Dossier' : 'Fichier';

            body.innerHTML = `
            <div class="card card-md">
                <div class="card-body p-3 text-center">
                    <div class="text-uppercase text-muted font-weight-medium mb-3">${type}</div>
                    <div class="mb-3"><i class="fas ${itemData.type === "dir" ? 'fa-folder' : 'fa-file'} fa-7x fa-fw"></i></div>
                    <h2 class="user-select-all mb-3">${itemData.name}</h2>
                                       
                    
                    <div class="table-responsive text-start">
                        <table class="table table-vcenter">
                            <tbody>
                            <tr class="${itemData.extension ? '' : 'd-none'}">
                                <td>Type de fichier :</td>
                                <td class="text-muted">${itemData.mimeType} (.${itemData.extension})</td>
                            </tr>
                            <tr class="${itemData.size ? '' : 'd-none'}">
                                <td>Taille :</td>
                                <td class="text-muted">${itemData.size} octets</td>
                            </tr>
                            <tr class="${itemData.createdAt ? '' : 'd-none'}">
                                <td>Créé le :</td>
                                <td>
                                    <div>${itemData.createdAt?.toLocaleDateString('fr-FR', localeDateStringOptions)}</div>
                                    <div class="text-muted ${itemData.createdBy ? '' : 'd-none'}">Par : ${itemData.createdBy}</div>
                                </td>
                            </tr>
                            <tr class="${itemData.updatedAt ? '' : 'd-none'}">
                                <td>Modifié le :</td>
                                <td>
                                    <div>${itemData.updatedAt?.toLocaleDateString('fr-FR', localeDateStringOptions)}</div>
                                    <div class="text-muted ${itemData.updatedBy ? '' : 'd-none'}">Par : ${itemData.updatedBy}</div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            `;

            const bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas);
            bsOffcanvas.show();
        }
    }

    /**
     * Affiche les propriétés de l'item sélectionné
     */
    showPropertiesSelectedItem() {
        this.showProperties(this.selectedItemUuid);
    }
}

/**
 * Event pour l'appui d'une touche sur tout `document`
 * @param e
 */
function onDocumentKeyup(e) {

    const guiIsVisible = this.itemListContainer.offsetParent !== null;

    if (guiIsVisible) {
        if (e.key === 'Delete') {
            this.deleteSelectedItem();
        }
    }
}

/**
 * Au lâcher d'un click sur le listing des items
 */
function onItemListMouseup(e) {
    e.stopPropagation();
    if (e.button === 0) {
        // Click gauche
        this.unselectAll();
    } else if (e.button === 2) {
        // Click droit

        this._contextmenu.items = [[
            new ContextMenuItemLink('Créer nouveau dossier', '#', this._onCreateFolderClick),
        ]];

        this._contextmenu.show(e.pageX, e.pageY);
    }
}

/**
 * À l'enfoncement d'un click sur un item
 */
function onItemMousedown(e, itemHTML) {
    e.stopPropagation();

    const uuid = itemHTML.dataset.uuid;
    this.selectItem(uuid);
}

/**
 * Au lâcher d'un click sur un item
 */
function onItemMouseup(e, itemHTML) {
    e.stopPropagation();

    const uuid = itemHTML.dataset.uuid;
    const itemData = this.getItemData(uuid);

    if (e.button === 2) {
        // Click droit

        const menu = new Set();

        const openItem = new ContextMenuItemLink('Ouvrir', '#', () => this._onItemDblClick(e, itemHTML));
        menu.add(openItem);

        menu.add(new ContextMenuItemSeparator());

        let hasItemAction = false;
        if (itemData.type === 'file') {
            hasItemAction = true;

            const moveToItem = new ContextSubMenuItem('Déplacer vers');
            const onClick = (folderTarget) => {

                const formData = new FormData();
                formData.append('uuid', uuid);
                formData.append('folderTargetUuid', folderTarget.uuid);

                fetch(this.moveItemUrlValue, {
                    method: 'POST',
                    body: formData,
                }).then(response => {
                    if (response.status === 200) {
                        this._contextmenu.hide();
                        this.refresh();
                    } else {
                        console.error('Error while moving item!');
                    }
                });
            }
            if (this._currentParentDir) {
                moveToItem.addItem(new ContextMenuItemLink("(Parent) " + this._currentParentDir.name, '#', () => onClick(this._currentParentDir)));
                moveToItem.addItem(new ContextMenuItemSeparator());
            }
            let hasDir = false;
            this.itemsData.forEach(item => {
                if (item.type !== "dir") return;
                moveToItem.addItem(new ContextMenuItemLink(item.name, '#', () => onClick(item)));
                hasDir = true;
            });
            if (!hasDir) {
                const noFolderItem = new ContextMenuItemLink('Aucun dossier')
                noFolderItem.disabled = true;
                moveToItem.addItem(noFolderItem);
            }

            moveToItem.disabled = itemData.type === 'dir';

            menu.add(moveToItem);
        }

        if (itemData.deletable) {
            hasItemAction = true;

            const deleteItem = new ContextMenuItemLink('Supprimer');
            deleteItem.disabled = this.hasDeleteItemUrlValue === false || itemData.deletable === false;
            deleteItem.action = () => this.deleteSelectedItem();
            deleteItem.faClass = 'fas fa-trash';
            if (itemData.deletable) deleteItem.class = 'bg-danger';

            menu.add(deleteItem);
        }

        if (itemData.renameable) {
            hasItemAction = true;

            const renameItem = new ContextMenuItemLink('Renommer');
            renameItem.disabled = this.hasRenameItemUrlValue === false || itemData.renameable === false;
            renameItem.action = () => this.editSelectedItem();

            menu.add(renameItem);
        }

        if (this.hasPublicItemUrlValue) {
            hasItemAction = true;

            const publicItem = new ContextMenuItemLink('');
            publicItem.disabled = this.hasPublicItemUrlValue === false || itemData.locked === true;
            publicItem.action = () => this.togglePublicSelectedItem();
            if (itemData.public) {
                publicItem.label = 'Rendre privé';
                publicItem.faClass = 'fas fa-eye-slash';
            } else {
                publicItem.label = 'Rendre publique';
                publicItem.faClass = 'fas fa-eye';
            }
            menu.add(publicItem);
        }

        if (hasItemAction) {
            menu.add(new ContextMenuItemSeparator());
        }

        const propertiesItem = new ContextMenuItemLink('Propriétés');
        propertiesItem.action = () => this.showProperties(itemData.uuid);

        menu.add(propertiesItem);

        this._contextmenu.items = [Array.from(menu)];

        this._contextmenu.show(e.pageX, e.pageY);
    }
}

/**
 * Au click d'un item
 */
function onItemClick(e, itemHTML) {
    e.stopPropagation();
}

/**
 * Au double click sur un item
 */
function onItemDblClick(e, itemHTML) {
    e.stopPropagation();

    const uuid = itemHTML.dataset.uuid;
    const itemData = this.getItemData(uuid);
    if (itemData.type === 'dir') {
        this.openDir(uuid);
    } else {
        this.openFile(uuid);
    }

}

/**
 * Au click sur le bouton retour historique
 * @param {Event} e
 * @param {HTMLButtonElement} prevHTML
 */
function onHistoricPrevClick(e, prevHTML) {
    e.stopPropagation();
    if (prevHTML.classList.contains('disabled')) return;

    this.goBack();
}

/**
 * Au click sur le bouton retour avant historique
 * @param {Event} e
 * @param {HTMLButtonElement} nextHTML
 */
function onHistoricNextClick(e, nextHTML) {
    e.stopPropagation();
    if (nextHTML.classList.contains('disabled')) return;

    this.goForward();
}

/**
 * Au click sur la demande de création d'un dossier
 */
function onCreateFolderClick() {
    (new TablerModal).ask('Nom du nouveau dossier')
        .then(folderName => {
            if (folderName) {
                this.createNewFolder(folderName);
            }
        })
    ;
}

/**
 * Au click sur le bouton Home
 */
function onHomeClick() {
    this.goHome();
}

/**
 * Au click sur le bouton Refresh
 */
function onRefreshClick() {
    this.refresh(true);
}

/**
 * Au changement de valeur de l'input de recherche
 * @param {Event} e
 * @param {HTMLInputElement} searchHTML
 */
function onSearchChange(e, searchHTML) {
    clearTimeout(this._navTools.children.search.input.timeout);

    this._navTools.children.search.input.timeout = setTimeout(() => {
        this.search(searchHTML.value);
    }, 500);
}

/**
 * Class permettant de gérer les item du Breadcrumb
 */
class JsFileExplorerPathItem {
    /**
     * @param {string} name
     * @param {URL} url
     */
    constructor(name, url) {
        this.name = name;
        this.url = url;
    }

    static fromJson(json) {
        return new JsFileExplorerPathItem(json.name, new URL(json.url, window.location.href));
    }
}

/**
 * Class permettant de gérer les item de l'explorateur :
 * - Fichier
 * - Dossier
 */
class JsFileExplorerItem {


    /** @type String */
    parentFolderUuid;
    /** @type URL */
    parentFolderUrl;

    /** @type string */
    name;
    /** @type string */
    type;

    /** @type string */
    icon;
    /** @type URL */
    folderUrl;

    /** @type URL */
    thumbnailUrl;
    /** @type URL */
    mediaUrl;
    /** @type number */
    size;
    /** @type string */
    mimeType;
    /** @type string */
    extension;

    /** @type Date */
    createdAt;
    /** @type Date */
    updatedAt;

    /** @type string */
    createdBy;
    /** @type string */
    updatedBy;
    /** @type string */
    deletedBy;

    /** @type boolean */
    disabled = false;
    /** @type boolean */
    public = false;
    /** @type boolean */
    locked = false;
    /** @type boolean */
    deletable = true;
    /** @type boolean */
    renameable = true;

    static fromJson(json) {
        const obj = new JsFileExplorerItem();

        obj.uuid = json.uuid;

        obj.parentFolderUuid = json.parentFolderUuid;
        obj.parentFolderUrl = json.parentFolderUrl ? new URL(json.parentFolderUrl, window.location.origin) : null;

        obj.name = json.name;
        obj.type = json.type;

        obj.icon = json.icon;
        obj.folderUrl = json.folderUrl ? new URL(json.folderUrl, window.location.origin) : null;

        obj.thumbnailUrl = json.thumbnailUrl ? new URL(json.thumbnailUrl, window.location.origin) : null;
        obj.mediaUrl = json.mediaUrl ? new URL(json.mediaUrl, window.location.origin) : null;
        obj.size = json.size;
        obj.mimeType = json.mimeType;
        obj.extension = json.extension;

        if (json.createdAt) obj.createdAt = new Date(Date.parse(json.createdAt));
        if (json.updatedAt) obj.updatedAt = new Date(Date.parse(json.updatedAt));

        obj.createdBy = json.createdBy;
        obj.updatedBy = json.updatedBy;
        obj.deletedBy = json.deletedBy;

        obj.disabled = json.disabled;
        obj.public = json.public;
        obj.locked = json.locked;
        obj.deletable = json.deletable;
        obj.renameable = json.renameable;

        return obj;
    }

    /**
     * @return {HTMLDivElement}
     */
    toHTMLElement() {

        const container = document.createElement('div');
        container.classList.add('item', 'col-12', 'col-sm-6', 'col-md-4', 'col-lg-2', 'col-xl-2', 'col-xxl-1');
        container.classList.add(this.disabled ? 'disabled' : 'cursor-pointer');
        container.dataset.uuid = this.uuid;

        const card = document.createElement('a');
        card.classList.add('card', 'border-0');

        const card_body = document.createElement('div');
        card_body.classList.add('card-body', 'text-center', 'p-0');
        card_body.style.position = 'relative';

        const row_preview = document.createElement('div');
        row_preview.classList.add('row', 'position-relative');

        if (this.thumbnailUrl) {
            const img_container = document.createElement('div');
            img_container.classList.add('fe-item-thumbnail-container');

            const img_placeholder = document.createElement('i');
            img_placeholder.classList.add('fe-item-thumbnail-placeholder', 'far', 'fa-image', 'fa-4x', 'fa-fw', 'p-2');
            img_container.appendChild(img_placeholder);

            const img = document.createElement('img');
            img.classList.add('fe-item-thumbnail', 'pt-2');
            img.src = this.thumbnailUrl.toString();
            // img.style.maxWidth = '70px';
            img.style.maxHeight = '70px';
            img.addEventListener('load', function () {
                this.parentNode.querySelector('.fe-item-thumbnail-placeholder').remove();
            }, {once: true});


            img_container.appendChild(img);
            row_preview.appendChild(img_container);
        } else if (this.icon) {
            const fa_container = document.createElement('div');
            fa_container.classList.add('fe-item-icon-container', 'p-2');

            const fa = document.createElement('i');
            fa.classList.add('fe-item-icon', ...this.icon.split(' '), 'fa-4x', 'fa-fw');

            fa_container.appendChild(fa);

            row_preview.appendChild(fa_container);
        }

        const subIcon_container = document.createElement('div');
        subIcon_container.classList.add('position-absolute', 'bottom-0', 'text-end');

        const addSubIcon = (faClass_icon) => {
            const fa = document.createElement('i');
            fa.classList.add(...faClass_icon.split(' '), 'fa-shadow-white', 'mx-1');
            subIcon_container.appendChild(fa);
        }

        if (this.public === false) {
            addSubIcon('fas fa-eye-slash');
        }

        if (this.locked) {
            addSubIcon('fas fa-lock');
        }

        row_preview.appendChild(subIcon_container);
        card_body.appendChild(row_preview);


        const row_name = document.createElement('div');
        row_name.classList.add('row', 'position-relative');

        const name = document.createElement('div');
        name.classList.add('fe-item-name', 'user-select-none', 'p-2');
        const dots = this.name.length > 40 ? '...' : '';
        name.innerHTML = this.name.substring(0, 40) + dots;

        row_name.appendChild(name);
        card_body.appendChild(row_name);

        card.appendChild(card_body);
        container.appendChild(card);

        return container;
    }
}
