import { Controller } from '@hotwired/stimulus';
export default class default_1 extends Controller {
    connect() {
        super.connect();
        this.onProjetChange();
    }
    onProjetChange() {
        const selected_projet = this.projetTarget[this.projetTarget.selectedIndex];
        const projet_help = this.projetTarget.form.querySelector(`#${this.projetTarget.id}_help`);
        if (!!selected_projet.value) {
            projet_help.innerText = "Version en cours : " + selected_projet.dataset.version;
        }
        else {
            projet_help.innerText = '';
        }
    }
}
default_1.targets = [
    "projet",
    "version",
];
