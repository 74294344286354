class Placement {

    static TopLeft      = new Placement("top-0 start-0");
    static TopCenter    = new Placement("top-0 start-50 translate-middle-x");
    static TopRight     = new Placement("top-0 end-0");
    static MiddleLeft   = new Placement("top-50 start-0 translate-middle-y");
    static MiddleCenter = new Placement("top-50 start-50 translate-middle");
    static MiddleRight  = new Placement("top-50 end-0 translate-middle-y");
    static BottomLeft   = new Placement("bottom-0 start-0");
    static BottomCenter = new Placement("bottom-0 start-50 translate-middle-x");
    static BottomRight  = new Placement("bottom-0 end-0");

    constructor(classNames) {
        this.classNames = classNames;
    }

    toString() {
        return this.classNames;
    }
}

export { Placement };
