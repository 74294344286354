import {Placement} from "../TablerToast";

class TablerToastContainer {
    /** @type {?HTMLDivElement} */
    element = null;
    /** @type {Placement} */
    placement = Placement.BottomRight;
    position = "fixed";

    /** @type {Set<TablerToast>} */
    toasts = new Set();

    /**
     * @param {?Placement} placement
     */
    constructor(placement = null) {
        if (placement) this.placement = placement;

        const container = document.createElement("div");
        container.classList.add("toast-container", `position-${this.position}`, "p-3", ...this.placement.toString().split(' '));
        this.element = document.body.appendChild(container);
    }

    /**
     * @param {TablerToast} toast
     */
    pushToast(toast) {
        this.toasts.add(toast);
        toast.container = this;
        toast.show();
    }
}

export { TablerToastContainer };
