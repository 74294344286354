import Cookies from "js-cookie";
const themeStorageKey = "tablerTheme";
const defaultTheme = "light";
let selectedTheme;
const urlTheme = new URLSearchParams(window.location.search).get('theme');
if (!!urlTheme) {
    Cookies.set(themeStorageKey, urlTheme, { sameSite: 'Lax' });
    selectedTheme = urlTheme;
}
else {
    const storedTheme = Cookies.get(themeStorageKey);
    selectedTheme = storedTheme ? storedTheme : defaultTheme;
}
document.querySelectorAll("[data-bs-theme]").forEach((el) => {
    el.setAttribute('data-bs-theme', selectedTheme);
});
